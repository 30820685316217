.checkoutPage{
    min-height: 120vh;
    background-color: #fff;
    font-size: 0.85rem;
    /* padding-top: 2rem; */
}
.checkout_flex {
    display: flex;
    flex-direction: column;
}
.checkout_main {
}
.checkout_main_header {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
        
}
.checkout_method {
    width: 8rem;
    background-color: blue;
    margin-right: 0.3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .3rem;
    border-radius: 4px; 
    opacity: 0.4;
}
.checkout_method.active {
    opacity: 1;
}
.checkout_aside {
    position: relative;
    max-height: 22rem;
    margin-top: 2rem;
}
.checkoutPage .cartSaveToLater {
    color: crimson;
    cursor: pointer;
}
.checkoutPage .cartItemName {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3em;
    line-height: 2em;
}
.loading_loader {
    margin-top: 2rem;
    text-align: center;
    font-size: 3rem;
    opacity: 0.7;
}
.coupon_code_input {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--gray);
    margin-top: 1rem;
}
.coupon_code_input.invalid {
    color: crimson;
    background-color: rgba(220, 20, 60, 0.178);
    border: 1px solid crimson;
}

.coupon_code_input.valid {
    color: green;
    background-color: rgba(0, 128, 0, 0.185);
    border: 1px solid green;
    font-weight: 400;
}
.coupon_code_input.in_use {
    color: goldenrod;
    background-color: rgba(218, 165, 32, 0.178);
    border: 1px solid goldenrod;
}
.checkout_aside_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85em;
    margin-block: 0.5rem;
    margin-right: 0.5rem;
}
.checkout_main_methods {
    text-align: center;
    margin-top: 2rem;
}
.checkout_main_methods button {
    background-color: var(--main-color);
}
.coupon_loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(78, 78, 78, 0.226);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.coupon_loader svg {
    width: 70px
}
.coupon_form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.coupon_form .verify_coupon_btn {
    background-color: var(--main-color);
    border-radius: 0px 4px 4px 0px;
}
.payModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    justify-content: center;
}
.payModal .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.payModal_content {
    background-color: #fff;
    z-index: 1002;
    width: 90%;
    max-width: 20rem;
    max-height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    border-radius: 6px;
}
.payModal h2 {
    font-size: 0.9rem;
    margin-top: 2rem;
}
.payModal .checkout_main_methods {
    margin: 0rem;
    height: 3rem;
}

@media(min-width: 50rem){
    .checkout_flex {
        flex-direction: row;
        justify-content: space-between;
    }
    .checkout_main {
        width: 65%;
    }
    .checkout_aside {
        width: 33%;
        border: 1px solid var(--gray);
        box-shadow: 0px 0px 2px 1px #adadad17;
        border-radius: 6px;
        padding: 1rem;
        height: 90rem;
        background-color: #f5f5ff;
        margin-top: 1rem;
    }
    .checkout_main_header {
        justify-content: center;
    }
    .checkout_main_header .checkout_method {
        margin-right: 1rem;
    }
    .checkout_main_methods {
        margin-top: 1rem;
    }
    
}
@media(max-width: 50rem){

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.674); /* Dim background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-2 {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .ok-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-weight: 400;
  }
  
  .ok-button:hover {
    background-color: #001a36;
  }
  .modal-text {
    font-size: 14px;
    font-weight: 400;
  }

.coupon_response {
    margin-top: 10px;
    padding: 5px;
    border-radius: 4px;
    text-align: center;
}

.coupon_response.invalid {
    background-color: #ffebee;
    color: #c62828;
    font-weight: 400;
}

.coupon_response.valid {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.coupon_response.in_use {
    background-color: #fff3e0;
    color: #f57c00;
}

.checkout_main_methods {
    margin-top: 20px;
}
  