
.head .icon{
        padding-right: 1rem;
}
.span_device{
        width: 100vw;
        margin-top: 5%;
        margin-left: calc(93% - 100%);
        overflow: hidden;
        overflow-x: scroll;
}
.span_device::-webkit-scrollbar{
        display: none;
}
.categories{
        margin: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-around;
}
.all_categories{
        font-weight: 500;
        cursor: pointer;
}
.deliver_title {
        font-size: .8rem;
        font-weight: 900;
}
.category {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}
.categories .imgWrapper{
        width: 50px;
        height: 50px;
        border-radius: 15px;
        background-color: #ee4c4c23;
}
.categories .desc {
        font-size: .8rem;
        margin-top: .5rem;
        text-transform: capitalize;
}
.showSuperDeals{
        display: block;
}
.hideSuperDeals{
        display: none;
}
.existBooks{
        display: none;
}
.notExistBooks{
        display: block;
}
.super_deals img{
        margin-block: 1rem;
        width: 8rem;
}
.super_deals .category > div{
        width: 100% !important;
}
/* .deals .categories {
        width: 160%;
} */
.deals .desc {
        color: #999;
}

.discountProduct {
        display: flex;
        margin-block: .5rem;
}
.delivery_img {
        width: 100%;
}
.star_rating_block{
        display: none;
}

.home_category_list {
        background-color: #fff;
        padding-block: 1rem;
}
.home_category_list ul {
        display: flex;
        width: 100%;
        justify-content: center;
}
.home_category_list li {
        padding: 0.4rem 1rem;
        border-radius: 4px;
        transition: all .3s ease;
}
.home_category_list li:hover {
        background-color: #e2e2ff;
}
.home_category_list .category_item {
        position: relative;
}
.home_category_list .category_item:hover  .cat_dropdown_indicator span {
        transform: rotate(180deg);
}
.home_category_list .drop_cat_list {
        position: absolute;
        width: 95%;
        background-color: #fff;
        transform: translateY(1%);
        border-radius: 4px;
        padding: 0px;
        overflow: hidden;
}
.home_category_list .category_item{
        margin: 0px !important;
}


/* ========= CATETORY LIST FOR NORMAL USER ================= */
.category_item_more {
        margin-left: auto;
        background-color: #fc8701;
        position: relative;
}
.category_item_more:hover{
        background-color: #ec8100 !important;
}
.category_dropdown_nu {
        position: absolute;
        top: 100%;
        right: 0;
        transform: translateY(1.5rem);
        border-radius: 4px;
        width: 250px;
        background-color: #fff;
        overflow: hidden;
        transition: all .3s ease-in-out;
        border: 1px solid #8880;
        height: 0px;
}



.category_dropdown_nu::-webkit-scrollbar {
        display: none;
}
.category_nu_link.sub_down {
        padding: 0.2rem 0.6rem;
        transition: all .3s ease-in;
        display: block !important;
}
.category_nu_link:hover {
        background-color: #e3e3fc;
}
.cat_title_nu {
        justify-content: space-between;
        display: flex;
        align-items: center;
        width: 100%;
        padding-block: 0.3rem;
        padding-left: 0.3rem;
}
.drop_cat_list.home .category_item a{
        padding: 0.3rem;
}
.cat_dropdown_indicator_nu {
        transition: 0.3s ease;
}
.cat_title_nu:hover .cat_dropdown_indicator_nu {
        transform: rotate(90deg) !important;
}
.sub_down_category_nu {
        height: 0px;
        overflow: hidden;
        transition: all .3s ease;
}

.category_nu_link:hover .sub_down_category_nu {
        height: auto;
        width: 100%;
}
.category_nu_link:hover {
        color: blue;
}
.category_nu_link  {
        padding: 0.2rem 0.6rem !important;
}
.dropdown_anchor {
        cursor: pointer;
        font-weight: 600;
        color: white;
}
.category_dropdown_nu.show_category_dropdown_nu{
        border: 1px solid #888;
        height: 30rem;
        cursor: pointer;
        overflow-y: scroll;
        z-index: 6;
}

.category_shadow {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 5;
}
.activeMenu{
        background-color: #e2e2ff;
}

/* =================================== */
.home_category_list .category_item:hover .drop_cat_list {
        border: 1px solid #ddd;
        box-shadow: 2px 2px 4px #0002;
        
}
.home_category_list .drop_cat_list .category_item{
        padding: 0px;
        padding-inline: 0.3rem;
}

@media(max-width: 60rem){
        .ribbon{
                display: none;
        }
        .home_category_list {
                display: none;
        }

        .categories{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin: auto;
        }
        .deals .head {
                font-size: 1.5rem;
                padding-right: .7rem;
                padding-left: 1rem;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                text-align:justify;
                width: auto;
                color: #555;
        }
        .more_to_love .head {
                font-size: 1.5rem;
                padding-right: .7rem;
                padding-left: 1rem;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                text-align:justify;
                width: auto;
                color: #555;
        }
        .deals .categories:nth-child(1){
                margin: 0 auto;
        }
        .responsive_display{
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                grid-gap: 1rem;
                place-items: center;
                width: 90%;
                margin: auto;
        }
        .discountProduct{
                background-color: #fff;
                border: 1px solid rgb(233, 233, 233);
                width: auto;
                /* min-width: 300px; */
                height: 16rem;
                padding: .5em;
                margin: 0;
                min-width: 10rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border-radius: 5%;
                /* gap: .5rem; */
        }
        .deals .imageWrapper{
                height: 60%;
                width: 100% !important;
                /* margin-right: 1rem; */
                margin: 0 !important;
                border-radius: 8px;
                max-height: fit-content;
                background-color: var(--light-accent-color); 
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
        }
        .deals .imageWrapper .navLinkImage {
                height: 100%;
        }
        .discount_info_section{
                /* background-color: rgb(140, 140, 130); */
                border-radius : 5px;
                height: 50%;
                min-height: 40%;
                width: 100%;
                position: relative;
                color: #fff;
        }
        .discount_product_name{
                height: 3rem;
                display: flex;
                align-items: center;
                padding: .1rem !important;
                color: #444;
        }
        .deals .price-discount{
                background-color: #fff;
                border-radius: 4px;
                color: orange;
                font-weight: 500;
                font-size: .65rem;
                /* margin-top: 2.5rem; */
                font-family: var(--fs-st);

        }
        .mobile_price{
                font-size: var(--fs-xs) !important;

        }
        .prices_section{
                display: flex;
                justify-content: flex-start;
                align-items: center;
        }
        .price{
                font-size: .8rem;
                font-weight: 600;

        }
        .priceOld{
                color: #777;
                text-decoration: line-through;
                margin-left: .5rem;
        }
        .mobile_new_price{
                padding: 0.2rem .8rem !important;
                background-color: #000080;
                color: #fff;
                border-radius: 4px;
                margin-right: 5%;
                text-align: start;
        }
        .bottom_section{
                display: flex;
                justify-content: space-between;
                gap: 50px;
                align-items: center;
                margin-top: .5rem;
        }
        .star_rating_block{
                display: block;
                /* position: absolute;
                bottom: 5%;
                right: 4%; */

                
        }
        
        .rating_star{
                color: orange;
               
                font-size: .7rem;
        }
        .null_rated_star{
                color: #777;
        }
}




.deals .imageWrapper{
        height: 7rem;
        width: 4.4rem;
        margin-right: 1rem;
        border-radius: 8px;
        background-color: var(--light-accent-color); 
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
}
.deals .imageWrapper .navLinkImage {
        height: 85%;
}
.discount_info_section{
        /* background-color: rgb(181, 181, 168);
        border-radius : 5px;
        /* padding: .5rem; 
        height: 40%; */
}
.deals span, .deals p {
        margin: 0;
        padding: 0;
        font-size: var(--fs-sm);
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        text-transform: capitalize;
}



/* ===================MORE TO LOVE SECTION ============================ */

.more_to_love {
        margin-top: 3rem;
}
.more_to_love .head {
        font-weight: 500;
}
.home_grid {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: .9rem;
}
.home_grid_item {
        justify-content: center;
        align-items: center;
        height: auto;
        min-height: 20rem;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid rgb(233, 233, 233);
        position: relative;
        max-width: 200px;
}
.home_grid .imgWrapper {
        height: 65%;
        background-color: var(--light-accent-color); 
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
}
.imgContraint {
        width: 80%;
        max-width: 9rem;
        height: 85%;
        position: relative;
        overflow: hidden;
}
.imgContraint > span {
        display: block;
        height: 100%;
        width: 100%;
}
.home_grid .imgWrapper .product_image {
        position: absolute;
        height: 100%;
        /* width: 100%; */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

}
.home_grid .desc {
        padding: .7rem;
        font-size: var(--fs-st);
        color: #555;
        text-transform: capitalize;
}
.home_grid .discount_product_name {
        display: block;
}

.home_grid .price-discount {
        margin-block: .3rem;
        font-family: var(--fs-st);
}
.tooltip {
        position: absolute;
        top: 70%;
        left: 10px;
        margin: 0 auto;
        background-color: #000080;
        color: white;
        white-space: pre-line;
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 11px;
        word-wrap: break-word;
        z-index: 1000;
        max-width: 200px;
        transition: all .8s ease;
}
.tooltip::after {
        content: "";
        position: absolute;
        top: -10px;
        left: 50%;
        z-index: 1000;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #000080 transparent transparent;
        transform: rotate(90deg);
        border-width: 8px;
}

.top_selling_tag {
        color: rgb(158, 7, 7);
        border: 1px solid rgb(207, 1, 1);
        border-radius: 4px;
        font-weight: 500;
        font-size: calc(var(--fs-sm) - 0.15rem);
        padding: .1rem .265rem;
        margin-right: .4rem;
}
.most_baught {
        color: rgb(7, 148, 158);
        border: 1px solid rgb(1, 142, 207);
}
.commic_tag {
        border-radius: red;
        color: #777;
        font-size: var(--fs-sm);
        background: linear-gradient(to right, rgba(32, 86, 235, 0.164) , rgba(37, 204, 255, 0.219));
}
.sold-rating {
        margin-block: 0px !important;
        display: flex;
        justify-content: space-between;
}
.sold-rating .rating {
        /* margin-left: 1rem; */
        font-weight: 500;
}
.sold-rating .rating .icon {
        margin-left: 0.4rem;
}
.more_to_love .sold {
        text-transform: lowercase;
        margin-right: .5rem;
}
.more_to_love .rating .icon {
        font-size: var(--fs-sm);
        padding-top: .3rem;
}
.more_to_love .discount_product_name {
        margin-top: 0.5rem;
        padding-bottom: 2rem;
        display: block;
        font-weight: 500;
}

.no_books_section {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: .5rem;
        background-color: rgba(200, 200, 250, .8);
        animation: 3s linear infinite alternate;
}
.no_books_section .message{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 1rem;
}
.no_books_title{
        font-size: 1.2rem;
        font-weight: 500;
        color: #555;
}
/* @keyframes no_book_animation{
        0%{
                background-color: ;
        }
} */
.no_books_text{
        font-size: 1rem;
        font-weight: 500;
        color: #555;
        text-align: left;
        text-transform: capitalize;
        max-width: 20rem;
}
.illustrative_image{
        width: 18rem;
        height: 13rem;
}


@media(min-width: 60rem) and (max-width: 1120px){

        .home_category_list li {
                padding: 0.4rem .6rem;
        }
}
@media(min-width: 60rem){
        .mobile_delivery {
                display: none;
        }
        .deals {
                background-color: #fff;
                border-radius: 1rem;
                padding-inline: 0rem 1rem;
        }
        .span_device{
                width: 100%;
                margin-left: 0px;
                overflow: hidden;
                position: relative;
        }
        .discount_product_name,
        .category .desc, .category_view_more {
                display: none;
        }
        .deals .categories {
                width: 100%;
                padding-inline: .5rem;
        }
        .deals .category {
                display: flex;
                flex-direction: row;
                margin: 0;
                width: 100%;
        }
        .discountProduct{
                flex-direction: column;
        }
        .ribbon {
                width: 150px;
                height: 150px;
                overflow: hidden;
                position: absolute;
        }
        .ribbon::before,
        .ribbon::after {
                position: absolute;
                z-index: -1;
                content: '';
                display: block;
                border: 5px solid red;
        }
        .ribbon span {
                position: absolute;
                display: block;
                width: 225px;
                padding: 15px 20px;
                background-color: red;
                box-shadow: 0 5px 10px rgba(0,0,0,.1);
                color: #fff;
                font-weight: 600; 
                font-size: 14px;
                text-shadow: 0 1px 1px rgba(0,0,0,.2);
                text-transform: uppercase;
                text-align: center;
        }
        
        /* top left*/
        .ribbon-top-left {
                top: -10px;
                left: -10px;
        }
        .ribbon-top-left::before,
        .ribbon-top-left::after {
                border-top-color: transparent;
                border-left-color: transparent;
        }
        .ribbon-top-left::before {
                top: 0;
                right: 0;
        }
        .ribbon-top-left::after {
                bottom: 0;
                left: 0;
        }
        .ribbon-top-left span {
                right: -25px;
                top: 30px;
                transform: rotate(-45deg);
        }
        .deals .head{
                display: none;
        }
        
        .deals .imageWrapper{
                width: 11rem;
                height: 13rem;
                margin-bottom: 1rem;
                background-color: var(--light-accent-color); 
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
        }
        .deals .imageWrapper .navLinkImage {
                height: 85%;
        }
        .deals p{
                margin-bottom: .5rem;
        }
        .deals .price-discount{
                background-color: var(--main-color);
                border-radius: 4px;
                color: #fff;
                padding: .3rem; 
                font-weight: 900;
                margin-top: 2.5rem;
                font-family: var(--fs-st);
        }
        .categories .category div >:last-child{
                margin-right: 0;
        }
        .price{
                color: var(--main-color);
                font-weight: bolder;
                text-wrap: nowrap;
        }
        .priceOld{
                color: #999;
                text-decoration: line-through;
                margin-left: 1rem;
        }

        .more_to_love .head {
                font-size: 2rem;
                padding: .1rem;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                text-align:justify;
                width: auto;

        }

        /* ============= MORE TO LOVE ============== */
        .home_grid {
                grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }
        .no_books_section .message{
                gap: 2rem;
        }
        .illustrative_image{
                width: 30rem;
                height: 20rem;
        }
        .no_books_text{
                font-size: 1.7rem;
                max-width: 30rem;
        }
        .no_books_title{
                font-size: 2.1rem;
                text-transform: capitalize;
        }
}
@media(max-width: 50rem) {
        .ebook_n .container{
                padding: 0rem;
        }
        .ebook_grid_n .home_grid {
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
        .ebook_n {
                padding-top: 2rem;
        }
        .ebook_list_n {
                display: none;
        }
        .ebook_list_n.showaside {
                display: block;
        }
        .tooltip {
                display: none;
        }
}

@media(max-width: 540px){
        .span_device .categories .category>div:last-child{
                display: none;
        }
        .no_books_title{
                font-size: 1.1rem;
        }
        .no_books_section .message{

        }
        .no_books_text{
                font-size: .9rem;
                max-width: 15rem;
        }
        .illustrative_image{
                width: 50%;
                height: 8rem;
        }

}
@media(max-width: 20rem) {
        .ebook_grid_n .home_grid {
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
        .home_grid .home_grid_item {
                max-width: 230px;
        }
}
