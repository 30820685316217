/* *{
    padding: 0;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
} */
.top-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}
.containers{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.seller_header{
    display: flex;
    background: linear-gradient(to right, rgba(3, 0, 34, 0.4), rgba(0, 0, 78, 0.4)), url('../../Assets/img/bg-shopseller-header.jpg') repeat center; 
    background-size: 100% auto;
    justify-content: center;
    width: auto;
    align-items: center;
    text-align:center;
    gap: 1%;
    color: #fff;
    position: relative;
}
.bloc_image{
    display: block;
    width: auto;
    background-size: cover;
    /* height: 10rem; */
    overflow: visible;

}
.profile_image{
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: rgba(46, 45, 45);
    bottom: -80%;
    position: relative;
    margin-left: 1rem;
    border: 2px solid #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    font-weight: 500;
}
/* .profile_image_subtitute{
    
} */
.infos{
    width: 100%;
    height: 100%;
    margin-top: .8rem;
    margin-right: .3rem;
    padding-bottom: .8rem;
}
p.welcome{
    width: 100%;
    text-wrap: wrap;
    font-size: 1.1rem;
    font-weight: 400;
    text-justify: center;
    margin: 0 auto;
    margin-bottom: 1rem;
    /* padding: .7rem; */
    padding-bottom: 0;
    text-align: center;
}

span.name{
    font-size: 1.3em;
    font-weight: 500;
}
.seller_address{
    text-align: left;
}
.date{
    font-size: .8rem;
    font-weight: 400;
    text-wrap: wrap;
    text-align: left;
    /* margin-bottom: .5rem; */
}
.about_and_stats{
    margin-top: 2rem;
}
.bio_seller{
    width: 95%;
    text-wrap: wrap;
    margin:  0;
    text-align: left;
}
p.contain{
    text-align: justify;
    margin-top: 1rem;
}
h2.title_about{
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
    align-items: center;
}
.contain{

}
.description p{
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
}
.expertise{
    background-color: #f8f9fa;
    padding: 1rem;
    width: auto;
}
.book{
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 20px 0;
}

.book .item_title{
    font-weight: 500;
    font-size: 1.1rem;
}
.item_value{
    padding-left: .5rem;
    list-style-type: none;
    font-size: .9rem;
    font-weight: 400;
}
.follow_section{
    font-weight: 500;
    font-size: 1.1rem;
    text-wrap: nowrap;
}
.share_links li {
    font-size: var(--fs-st);
    color: #1177ff;
    font-weight: 700;
    padding: 0.4rem 0.5rem;
    margin-bottom: 0.3rem;
    transition: all .1s ease-in;
    background-color: transparent;
}
.share_links li a {
    font-weight: 500;
}
.share_links li:hover {
    background-color: #f2f2ff;
    border-radius: 20px;
}
.share_links .copy_store {
    background-color: #f8f8f8;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border: 1px solid var(--gray);
}
.share_links .copy_store:hover {
    background-color: #f1f1f1;
}
.d_flex_link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
}
.share_links .copy_store {
    background-color: #f8f8f8;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border: 1px solid var(--gray);
}
.copy_store span {
    font-weight: 400;
    color: #4b4b4b;
}
.share_links .copy_store:hover {
    background-color: #f1f1f1;
}
.book_collection_container{
    width: auto;
    margin-left: 3rem;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 500;
}
.filter_text{
    font-weight: 400;
    padding-right: .3rem;
    color: rgba(3, 0, 34, .8);
}
.filter_select{
    border: 2px solid rgba(3, 0, 34, 0.4);
}
@keyframes fadeInSlideDown {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
/* Animation pour l'entrée de gauche */
@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Animation pour l'entrée de droite */
@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.seller_header, .bloc_image, .infos{
    animation: fadeInSlideDown 1.5s ease-out;
}
/* Styles de base */
.bio_seller,
.expertise {
    opacity: 0; /* Les éléments sont invisibles avant l'animation */
    display: inline-block; /* Pour s'assurer qu'ils se comportent comme des blocs en ligne */
    animation-duration: 1.2s; /* Durée de l'animation */
    animation-fill-mode: forwards; /* Maintient le dernier état de l'animation */
}

/* Animation spécifique */
.bio_seller {
    animation-name: slideInFromLeft;
    animation-delay: 0.5s; /* Délais pour un effet séquencé */
}

.expertise {
    animation-name: slideInFromRight;
    animation-delay: 0.7s;
}

@media (min-width: 600px){

    .top-container{
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
    }
    .containers{
        margin: 2rem;
        width: 100%;
        display: flex;
    }    
    .seller_header{
        display: flex;
        background: linear-gradient(to right, rgba(3, 0, 34, 0.4), rgba(0, 0, 78, 0.4)), url('../../Assets/img/bg-shopseller-header.jpg') no-repeat center; 
        background-size: 100% auto;
        justify-content: center;    
        width: 97%;
        min-height: 7rem;
        align-items: center;
        text-align:center;
        gap: 5%;
        color: #fff;
        position: relative;
    
    }
    .bloc_image{
        /* display: block; */
        width: auto;
        background-size: cover;
        height: 10rem;
        overflow: visible;
    }

    .profile_image{
        width: 9rem;
        height: 9rem;
        bottom: -17%;
        position: relative;
        margin-left: 20%;
        /* padding: 1rem; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.5rem;
        font-weight: 500;
    }
    .infos{
        width: 100%;
        height: 100%;
        margin-top: 0;
        margin-right: 0;
        padding-bottom: 0;
    }
    p{
        text-align: left;
        /* margin: 1rem auto; */
        overflow-wrap: break-word;
    }
    p.welcome{
        width: 98%;
        font-size: 1.4rem;
        font-weight: 500;
        text-justify: left;
        padding-top: 1rem;
        width: auto;
        /* line-height: 1.63; */
        padding: 0;
        padding-bottom: 0;
        text-align: left;
    }

    .about_and_stats{
        margin-top: 4rem;
        display: grid;
        grid-template-columns: 65% 28%;
        gap: .3rem;

    }
    .bio_seller{
        width: 95%;
        text-wrap: wrap;
        margin:  0;
    }
        p.contain{
        margin-top: 1rem;
    }
       .title_about{
        font-size: 1.8rem;
        font-weight: 500;
        text-align: start;
    }


    .expertise{
        width: auto;
        padding: 0 1rem;
    }
    .expertise .link{
        list-style: none;
        text-decoration: none;
    }
    ul{
        text-decoration: none;
    }
    .book_collection_container{
        width: 100%;
        font-size: 2rem;
        text-align: center;
        text-transform: capitalize;
    }
    .filter_text{

    }
    .filter_select{

    }
}

@media(max-width: 750px){

}