.mybooks_page .main_user_section {
        width: 90%;
        max-width: 80rem;
        margin: 3rem auto;
}
.loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 10;
}

.loader-icon {
        font-size: 3rem;
        color: #011b33;
        animation: spin 1s linear infinite;
}


@keyframes spin {
        from {
                transform: rotate(0deg);
        }
        to {
                transform: rotate(360deg);
        }
}

.main_user_section {
        padding: 0.5rem;
}
.paid_books_title {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
}
.books_sub_heading{
        display: flex;
        align-items: center;
        gap: 1rem;
}
.paid_title{
        font-size: 2rem;
        font-weight: 400;
        width: 50%;
}
.paid_books_title > a{
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background-color: #000080;
        color: white;
        padding: 0.5rem 0.8rem;
        border-radius: 1rem;
}
.paid_books_title > a:hover{
        background-color: #011b33;
}
.search_book_store{
   width: 50%;
   border-radius: 0.4rem;
   background-color: #fff;
   border: 1px solid #011b33;
   padding: 0.7rem 0.5rem;
   font-size: 0.8rem;
   font-weight: 400;
   font-family: 'Montserrat', sans-serif;
}
.user_main_grid {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.user_main_grid .book {
        flex: 1 1 auto;
        width: 300px;
        border: 1px solid #dee2e6;
        display: flex;
        position: relative;
        background-color: #fff;
}
.book_profile_pic {
        width: 40%;
        height: 100%;
        overflow: hidden;
}
.book_profile_pic img{
        width: 100%;
        height: 100%;
        cursor: pointer;
}
.book_profile_desc {
        width: 60%;
        padding: 1rem;
        position: relative;
}
.book_profile_desc * {
        font-size: 0.8em;
}
.book_profile_desc .book_name {
        font-weight: 500;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
}
.my_book_author {
        max-height: 55px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
}
.book_profile_desc .small {
        color: rgb(2, 2, 75);
        font-size: 0.8em;
        margin-top: 0.3rem;
        cursor: pointer;
}
.book_profile_desc .small .icon {
        font-size: 0.9em;
        margin-right: 0.7rem;
}
.book_profile_desc .review_icon{
        margin-top: 0.5rem;
        display: block;
        margin-right: 0.5rem !important;
}
.book_profile_desc .review_icon *{
        font-size: 0.8rem !important;
}
.book_profile_desc .review_anchor {
        display: flex;
        align-items: center;
}
.book .color-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--golden-yellow);
        position: absolute;
        top: 0;
        right: 0;
        margin: 0.5rem;
}

.color-dot.color-dot-reviewed{
        background-color: var(--main-green);
}

.color-dot.color-dot-reject{
        background-color: crimson;
}

.seller_with_no_book {
        text-align: center;
        margin-block: 2rem;
}
.color-dot.admin_book {
        background-color: var(--main-red);
}
.user_main_grid .seller_with_no_book {
        grid-column: 1/ -1;
        width: 100%;
}
.my_book_item .leave_review {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 10px;
        opacity: 0.85;
        padding: 0.3rem;
}

/* ====== BOOK REVIE MODAL ==== */
.review_modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #dee2e6cc;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;
}
.review_modal_content {
        width: 90%;
        height: auto;
        min-height: 10rem;
        background-color: #fff;
        border-radius: 8px;
        max-width: 600px;
        padding: 1rem;
        padding-inline: 3rem;
        position: relative;
        justify-content: center;
        display: flex;
        flex-direction: column;
}
.review_modal_content .close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: #dee2e6;
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
}
.review_modal_content .close * {
        font-size: 0.85rem;
}
.review_modal_rating,
.review_modal_comment {
        margin-top: 1rem;
}
.review_modal_rating h4,
.review_modal_comment h4 {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 1rem;
}
.review_modal_rating h4 {
        margin-bottom: 0.3rem;
}
.review_modal_comment textarea {
        width: 100%;
        border: 1px solid #cfd2d4;
        outline: none;
        resize: none;
        border-radius: 4px;
        padding: 1rem;
}
.review_modal_comment textarea::-webkit-scrollbar {
        display: none;
}
.review_modal_stars * {
        font-size: 1.5rem;
        margin-left: 0px !important;
}
.save_review_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: var(--main-color);
}
.save_review_btn span {
        margin-right: 0.5rem;
}

@media(max-width: 50rem){
        .mybooks_page .search_panel {
                display: none;
        } 
        .review_modal_content {
                padding-inline: 1rem;
        }
}

@media (max-width: 768px) {
        .books_sub_heading {
                flex-direction: column; /* Empile les éléments verticalement */
                align-items: flex-start; /* Aligne les éléments à gauche */
                gap: 0.5rem;
        }

        .paid_title {
                font-size: 1.5rem;
                width: 100%;
        }

        .search_book_store {
                width: 100%;
        }
}


@media (max-width: 480px) {
        .paid_books_title{
                display: flex;
                align-items: baseline;
        }
        .paid_title {
                font-size: 1.2rem;
                display: flex;
        }

        .search_book_store {
                font-size: 0.7rem;
                padding: 0.5rem;
        }

        .paid_books_title > a {
                font-size: 0.7rem;
                padding: 0.3rem 0.5rem;
        }

}