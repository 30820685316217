@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700&family=Open+Sans:wght@300;400;500;600;700;800&family=Quicksand:wght@300;400;500;600;700&display=swap');
html {
    scroll-behavior: smooth;
}
  
body {
    font-family: DM Sans,Open Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    line-height: 1.5;
      /* background-color: rgb(246,253,254); */
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(min-width: 40rem){
    body {
        /* background-color: #f2f2f2;s */
    }
}
@media (max-width: 780px) {
    html {
        overflow-x: hidden;
        width: 100%;
        box-sizing: border-box;
    }
    body {
        margin: 0 auto; 
        width: 100%; 
        object-fit: contain;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans';
    font-weight: 300;
}

*:focus{
    outline:none;
}
* input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}
input[type='radio'] {
    accent-color: var(--main-color);
    transform: scale(1.4);
}

.loading_skeleton {
    animation: loading_data 1s linear infinite alternate;
}
.skeleton_text {
    width: 90%;
    height: 1.2em;
    border-radius: 4px;
}
.skeleton_off {
    width: 4rem;
    display: block;
    margin: 0px !important;
    height: 2rem;
}
.loading_skeleton.skeleton_rating{
    height: 1.4rem;
    border-radius: 4px;
    margin-block: 0.4rem !important;
    width: 50%;
}
@keyframes loading_data {
    0% {
        background-color: #dbdbdb25;
    }
    100% {
        background-color: #dbdbdb;
    }
}

@media(max-width: 50rem){
    body{
       overflow-x: hidden;
    }
    .loading_skeleton.skeleton_text {
        margin-bottom: 1rem;
        height: 1.4rem;
    }
    .price.loading_skeleton.skeleton_text {
        width: 100%;
    }
}