.nav_desktop_dropdown_ne {
        background-color: #fff;
        min-height: 15rem;
        position: absolute;
        margin-top: 35%;
        left: 50%;
        transform: translateX(-55%);
        width: 100vw;
        box-shadow: 0px 2px 5px #30303013;
        transition: all 0.5s ease-in-out;
        visibility: hidden;
        z-index: 10;
}

.category_wrapper_n .nav_desktop_dropdown_ne {
        width: 100vw; /* Ensures the dropdown stays full width for this context */
        top: 100%; /* Adjusted positioning as required */
        box-shadow: 2px 2px 4px #3030301f;
        transition: all 0.5s ease-in-out 0.5s;
        max-height: 40rem;
        overflow-y: scroll;
}


.nav_desktop_dropdown_ne::-webkit-scrollbar-thumb {
        background-color: #3030301f;
}
.nav_desktop_dropdown_ne::-webkit-scrollbar {
        width: 0.5rem
}
.category_wrapper_n .nav_desktop_dropdown_ne {
        width: 200%;
        /*top: 180%;*/
        box-shadow: 2px 2px 4px #3030301f;
        transition: all 0.5s ease-in-out 0.5s forwards;
        max-height: 40rem;
        overflow-y: scroll;
}
.nav_grid_ne {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
        margin-block: 1rem;
}
.category_wrapper_n .nav_grid_item_ne {
        margin-bottom: 0px;
}
.category_wrapper_n .title {
        display: flex;
        justify-content: space-between;
}
.nav_list_n.explore_btn_n.category_wrapper_n:hover{
        border-bottom: none !important;
        padding: 0px;
}
.nav_list_n.explore_btn_n.category_wrapper_n .explore_link_n{
        display: flex;
}
.nav_list_n.explore_btn_n.category_wrapper_n .icon{
        margin-top: -0.15rem;
}
.sub_down_category_n {
        height: 0px;
        overflow: hidden;
        transition: all .3s ease-in-out;
        font-weight: 400;
}
.nav_grid_item_ne:hover .sub_down_category_n {
        height: auto;
}
.nav_grid_item_ne .cat_dropdown_indicator {
        transition: all .3s ease-in-out;
}
.nav_grid_item_ne:hover .cat_dropdown_indicator {
        transform: rotate(90deg);
}
.nav_grid_item_ne {
        height: 100%;
        margin-bottom: 1rem;
        font-size: var(--fs-st);
        color: black;
}
.nav_desktop_n .nav_desk_n .nav_list_n .nav_desktop_dropdown_ne a{
        color: black;
        transition: all .3s ease-in-out;
}
.nav_desktop_n .nav_desk_n .nav_list_n .nav_desktop_dropdown_ne a:hover{
        color: #0404db;
        padding-left: 0.3rem;
}
.nav_grid_item_ne .title{
        font-weight: 500;
        margin-bottom: .5rem;
}
.explore_btn_n .nav_link_n{
        cursor: pointer;
        pointer-events: none;
}
.nav_desktop_dropdown_ne:hover .nav_desktop_dropdown_ne,
.explore_btn_n:hover > .nav_desktop_dropdown_ne{
        visibility: visible;
}

.explore_btn_n .icon {
        padding-top: .3rem;
        padding-left: 1rem;
}
.explore_btn_n.category_wrapper_n {
        position: relative;
}
.explore_link_n {
        color: #fff;
        font-size: 0.9em;
        z-index: 2000;
        font-weight: 400;
        text-transform: capitalize;
        cursor: pointer;
}


@media(max-width: 50rem){
        .nav_desktop_dropdown_ne {
                display: none;
        }
}