.chat_app{
        background-color: #fff;
        position: fixed;
        bottom: 0;
        width: 100vw;
        z-index: 1;
        font-size: .8rem;
}        
        
.chatSystem{
        height: 100vh;
        width: 100%;
        padding-top: 1rem;
        font-size: .85rem;
        margin: auto;
        max-width: 93.75rem;
}
.chat{
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
}

.chatSystem__header{
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: .7rem;
}
.chatSystem__header span{
        margin-right: 1rem;
}
.noConversation{
        background-image: radial-gradient(circle at 29% 55%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 4%,transparent 4%, transparent 44%,transparent 44%, transparent 100%),radial-gradient(circle at 85% 89%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 51%,transparent 51%, transparent 52%,transparent 52%, transparent 100%),radial-gradient(circle at 6% 90%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 53%,transparent 53%, transparent 64%,transparent 64%, transparent 100%),radial-gradient(circle at 35% 75%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 6%,transparent 6%, transparent 98%,transparent 98%, transparent 100%),radial-gradient(circle at 56% 75%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 16%,transparent 16%, transparent 23%,transparent 23%, transparent 100%),radial-gradient(circle at 42% 0%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 3%,transparent 3%, transparent 26%,transparent 26%, transparent 100%),radial-gradient(circle at 29% 28%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 51%,transparent 51%, transparent 75%,transparent 75%, transparent 100%),radial-gradient(circle at 77% 21%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 35%,transparent 35%, transparent 55%,transparent 55%, transparent 100%),radial-gradient(circle at 65% 91%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 46%,transparent 46%, transparent 76%,transparent 76%, transparent 100%),linear-gradient(45deg, rgb(83, 91, 235),rgb(76, 11, 174));
        width: 100%;
        height: 100%;
        border-radius: 8px;
}
.seller_chat_minor .chat_messages .container{
        width: 100%;
}
.seller_chat_minor .chat_messages{
        background-color: #fff;
}

.chat_messages{
        padding-block: 1rem;
        width: 100%;
        overflow-y: scroll;
        transition: all 1s ease-in-out;
}
.chat_messages::-webkit-scrollbar{
        width: .3rem;
}

.chat_messages::-webkit-scrollbar-thumb{
        background-color: var(--clr-accent);
}
.chat_messages .container{
        width: 90%;
        margin: auto;
}
.chatSystem .person, .seller_chat_minor .person{
        border-top: 1px solid #eee;
        transition: all .5s ease-in-out;
        cursor: pointer;
}
.chatSystem .person, .seller_chat_minor .person:hover{
        background-color: #fcfcfc;
}
.chatSystem .person .active{
        border-bottom: none;
        background-color: #000;
}
.chat_messages .person{
        display: flex;
        padding-block: .6rem;
        width: 100%;
}
.chat_messages .personWrapper{
        width: 100%;
}
.person_profile{
        width: 3.5rem;
        height: 3rem;
        border-radius: 100%;
        background-color: #9F82BB;
        margin-right: .5rem;
}
.name_time{
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: var(--fs-st);
        margin-bottom: .2rem;
}

.chat_messages .chat_person_name{
        font-weight: 500;
}
.chat_person_time {
        font-size: .6rem;
}
.chat_last_message {
        font-size: var(--fs-st);
}

/* =======================CHAT CONTENT======================== */
.chat .chat_content{
        border-radius: 8px;
        width: 100%;
        position: relative;
        transform: translateX(100%);
        transition: all 1s ease-in-out;
        display: none;
}

.chat_content .messageSection{
        position: absolute;
        overflow-y: scroll;
        top: 0;
        padding: .7rem;
        left: 0;
        width: 100%;
        height: 100%;
}
.chat_content .messageSection::-webkit-scrollbar{
        display: none;
}
.chat_messageInput{
        position: sticky;
        bottom: 0%;
        /* left: 0; */
        z-index: 20;
        width: 90%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        margin: auto 1.5rem;
        align-items: center;
        display: flex;
        background-color: blue;
}
.chat_content .chat_messageInput .send_message_btn{
        background-color: #9F82BB;
        color: #fff;
        padding: .6rem 1rem;
        margin: 1rem;
        border-radius: 4px;
}
.chat_input{
        background-color: #D9D9D9;
        width: 95%;
        margin: auto;
        border-radius: .5rem;
        margin-bottom: 1rem;
}
.chat_input textarea{
        all: unset;
        resize: none;
        width: 100%;
        height: 2rem;
        padding-top: 1rem;
        padding-left: .3rem;
        max-height: 5rem;
}

.messageText{
        display: flex;
        margin-bottom: 1rem;
        margin-inline: 1rem;
}
.messageText .text{
        border-radius: 1rem;
        padding: .5rem;
        padding-right: 1rem;
        background-color: #D9D9D9;
        max-width: 500px;
}
.messageText .noMeSend{
        border-bottom-left-radius: 0rem;
}
.messageText .meSend{
        border-bottom-right-radius: 0rem;
        margin-left: auto;
        background-color: #9f82bb33;
        
}
.messageText .message_time{
        font-size: .6rem;
        display: block;
        text-align: right;
        margin-top: .2rem;
}

.conversationWithPerson{
        overflow: hidden;
        height: calc(100vh - 4rem);
}
.conversationWithPerson .chat{
        display: block;
        height: 100%;
}
.conversationWithPerson .chat_messages{
        transform: translateX(-100%) scale(.7);
}
.conversationWithPerson .chat .chat_content{
        display: block;
        transform: translateX(0);
        width: 100%;
        height: calc(100% - 4rem);
}
.conversationWithPerson .chat .chat_messages{
        display: none;
}

.dashboard_chat_title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: var(--color-main);
        font-weight: 500;
        padding: 1rem;
        border-bottom: 1px solid var(--gray);
        margin-bottom: 2rem;
}
.dashboard_chat_title > span {
        cursor: pointer;
}

.dashboard_chat_title span:nth-child(1) {
        padding-right: 0.3rem;
}
.dashboard_chat_title .return_link_icon_sd_right{
        padding-left: 0.5rem;
        padding-top: 0.2rem;
}
@media(min-width:37.75rem){
        .conversationWithPerson .chat{
                display: flex;
                height: 98%;
        }
        .conversationWithPerson .chat_messages{
                transform: translateX(0%) scale(1);
        }
        .conversationWithPerson .chat .chat_content{
                display: block;
                transform: translateX(0);
                width: 100%;
                height: calc(100% - 4rem);
        }
        .conversationWithPerson .chat .chat_messages{
                display: block;
        }


        .chatSystem{
                width: 98%;
                margin-top: 5rem;
                height: calc(100vh - 6rem);
        }
        .chat{
                height: calc(100% - 6rem);
        }
      .chat_messages{
                background-color: #CCCCE6;
                max-width: 25rem;
                margin-right: 2rem;
                border-radius: 8px;
                height: 100%;
      }  
        .chat .chat_content{
                display: block;
                position: relative;
                border: 1px solid #000;
                transform: translateX(0%);
        }
        .chat_content .chat_messageInput{
                width: 90%;
                margin:  auto 3rem;
        }
        .chat_messages .container{
                max-width: 31.25rem;
        }
        .person_profile .active{
                background-color: blue;
                border: 2px solid pink;
        }
        
}       
