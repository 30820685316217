@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');
.guide_page {

}
/* ============ HERO SECTION ============ */
.guide_landing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block: 3rem 5rem;
}
.guide_landing >div {
        width: 100%;
}
.guide_landing h2 {
        font-size: 2em;
        font-weight: bold;
}
.guide_landing .graphics {
        position: relative;
        width: 100%;
        overflow: hidden;
}
.guide_landing .graphics svg {
        height: 100%;
}
.guide_landing .landing_hover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 2px 2px 5px rgba(146, 146, 146, 0.192);
        padding: 2rem;
        width: 90%;
        max-width: 30rem;
}
.landing_hover .skew_landing {
        transform: skewX(10deg);
}
.landing_hover .unskew_landing {
}
.landing_hover .skew_landing .guide_country {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
}
.landing_hover .bubble_flag {
        width: 3rem;
        height: 3rem;
        background-color: #f5f5ff;
        border-radius: 50%;
        margin-right: 1rem;
        overflow: hidden;
        position: relative;
}
.landing_hover .skew_landing span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150%;
        height: 150%;
        transform: translate(-50%, -50%);
}
.landing_hover .small_text_country {
        font-size: 0.8em;
        color: #999;
}

.graphics .container {
        width: 150px;
        height: 150px;
        display: grid;
        overflow: hidden;
        margin: 0;
        list-style: none;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 15px;
        padding: 15px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50px;
      }
      
      .item {
        background: blue;
        border-radius: 100%;
      }
      
/* ============== VIDEO SECTION =============== */
.tour_vid {
        background-color: var(--main-color);
        color: #e1e1e1;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        min-height: 25rem;
        width: 100%;
        margin-top: 3rem;
}
.tour_vid .description {
        width: 40%;
        line-height: 2rem;
}
.tour_vid .description p {
        margin-top: 1.8rem;
        margin-left: 1.8rem;
        font-weight: 200;
        font-size: 17px;
        font-family: 'Merriweather', serif;
}
.video_wrapper {
        width: 50%;
        position: relative;
}

.video_wrapper .tour_vid {
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 3px;
        background-color: white;
        height: 15rem;
        transform: translate(-1rem, -30%);
}


/* =========BLOG ============ */
.guide_blog {
        margin-top: 3rem;
}
.blog_grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 2rem;
}
.blog_item .blog_image {
        height: 14rem;
        position: relative;
        overflow: hidden;
}

.blog_item .blog_image img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);

}
.blog_item .description {
        display: flex;
        align-items: center;
}
.blog_item .moreLink {
        background-color: crimson;
        color: #fff;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease-in-out;
}
.blog_item:hover > .description .moreLink {
        background-color: var(--main-color);
}
.guide_blog .blog_desc_text {
        width: calc(100% - 3rem);
        background-color: #fff;
        margin-top: -2rem;
        font-size: 0.8em;
        padding: 1.5rem 0.8rem;
        position: relative;
        z-index: 2;
}
.blog_desc_text a {
        font-weight: 500;
        margin-bottom: 0.5rem;
        display: block;
}
.blog_desc_text a:hover {
        text-decoration: underline;
}


.guide_title {
        font-weight: 500;
        font-family: 'Merriweather', serif;
        font-size: 1.3em;
        margin-bottom: 1rem;
        background-color: #F3F0EC;
        display: flex;
        padding: 1rem;
        width: 80%;
        max-width: 40rem;

}
/* ========== GUIDE CARD =============== */
.guide_card {
        width: 80%;
        max-width: 30rem;
        border: 2px solid #358380;
        margin: 3rem auto;
        display: flex;
}
.guide_card .numbers_section {
        width: 60%;
        color: #358380;
        padding: 1rem;
}
.guide_card .numbers_section {
        display: flex;
        align-items: center;
}
.guide_card .description {
        margin-left: 0.5rem;
        font-weight: bold;
}
.guide_card .number_big {
        font-size: 1.9em;
}
.guide_card .link_section {
        background: linear-gradient(to right, #358380, #3F865F);
        width: 42%;
        color: #fff;
        padding: 1rem;
}
.guide_card .link_section a{
        background-color: #fff;
        padding: 0.3rem 1rem;
        border: 1px solid #358380;
        color: #358380;
        border-radius: 30px;
        margin-block: 0.7rem;
        display: inline-block;
}
@media(max-width: 50rem){
        .tour_vid {
                display: block;
        }
        .tour_vid .description,
        .tour_vid .video_wrapper{
                width: 100%;
        }
        .video_wrapper .tour_vid {
                position: static;
                height: 7rem;
                transform: translate(0px, 0px);
                all: unset;
        }
        .guide_landing .graphics {
                height: 15rem;
        }
        .guide_landing {
                display: block;
        }
        .guide_card {
                width: 100%;
                display: block;
        }
        .guide_card >div {
                width: 100% !important;
                margin: 0px;
        }
}