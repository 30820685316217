.non_user_nav {
        background-color: var(--main-color);
        position: relative;
        z-index: 1000;
}
.non_user_nav .mobile-top {
        position: relative;
        z-index: 999;
        padding: 0.5rem;
        background-color: #fff;
        transition:  all .35s ease-in-out;
        padding-block: 1.5rem;
}
.non_user_nav .burger {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: .4rem;
        margin-right: .6rem;
        font-size: 1.6rem;
        color: #000;
        z-index: 1001;
        padding-block: 0.9rem;
        padding-right: calc(5% - 2px);
        opacity: 0;
        transition: all .4s ease-in-out;
        opacity: 1;
        display: flex;
        align-items: center;
}

.nav_n_logo {
        margin: 1.5rem;
        color: #fff !important;
}

.mobile_nav_n {
        width: 102%;
        position: fixed;
        top: -1%;
        left: -1%;
        z-index: 1000;
        transition:  all .35s ease-in-out;
        background-color: var(--main-color);
}
.unclip {
        clip-path: circle(0px at calc(100% - 40px) 45px);
        height: 103vh;
}
.non_user_nav  .whiteLogo{
        color: #fff;
}
.language_drop_container {
        position: relative;
}
.language_dropdown_n {
        position: absolute;
        top: 100%;
        left: 0;
        width: 150%;
        min-width: 150px;
        height: 0px;
        overflow: hidden;
        opacity: 0;
        border: 1px solid var(--main-color);
        background-color: #fff;
        padding: 0.5rem;
        font-size: 0.85em;
        transition: all .3s ease;
}
.language_drop_container .nav_link_n:hover + .language_dropdown_n, .language_dropdown_n:hover{
        height: auto;
        opacity: 1;
}

.language_dropdown_n ul{
        display: block !important;
        width: 100% !important;
}
.language_dropdown_n ul li {
        display: flex;
        padding: 0.2rem;
        cursor: pointer;
        align-items: center;
}
.lang_change_mobile {
        display: inline;
}
.the_lang_btn {
        background-color: transparent;
        border-radius: 4px;
        margin-right: 2rem;
        padding: 6px;
        height: 40px;
}
.dropdown_mobile_lang_content {
        position: absolute;
        top: 80%;
        left: 0;
        background-color: #fff;
        border: 1px solid var(--gray);
        width: 100%;
        height: auto;
        font-size: 0.85rem;
        border-radius: 4px;
}
.dropdown_mobile_lang_content ul {
        padding: 0px !important;
}
.dropdown_mobile_lang_content ul li{
        padding: 0.2rem;
        padding-left: 0.5rem;
}
.burger_self {
        padding: 2px 0.5rem;
        display: flex;
        align-items: center;
}

.non_user_nav .showNavN {
        height: 103vh;
        clip-path: circle(200% at 95% 0%);
}

.mobile_nav_n ul {
        padding: 3rem 2rem;
}
/* =========== */

.nav_list_n {
        display: block;
        margin-top: 1rem;
}
.nav_link_n{
        color: #fff;
        font-size: var(--fs-st);
        text-transform: capitalize;
        font-weight: 500;
}
.nav_link_n .icon{
        padding-right: 1rem;
}
.nav_desk_n {
        display: flex;
        justify-content: space-between;
        height: 6rem;
        align-items: center;
}



@media(min-width: 50rem) {
        .mobile_nav_n {
                display: none;
        }

        .nav_desk_n ul {
              width: 67%;
              display: flex;
              justify-content: space-between;
              align-items: center;  
        }

        .nav_desk_n .signup_btn_n {
                padding: .3rem .7rem;
                border: 1px solid #fff;
                border-radius: 5px;
        }

        .nav_desk_n ul li {
                position: relative;
                list-style: none;
                border: 1px solid #ffffff04;
                transition: all 0.25s ease-in-out;
        }

        .nav_desk_n ul li:not(:last-child)::after {
                content: '';
                width: 0%;
                height: 2px;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: width 0.2s ease-in-out;
        }

        .nav_desk_n ul li:not(:last-child):hover::after {
                width: 100%;
        }

        .nav_desk_n .signup_li:hover {
                padding: 0;
                border: none;
        }

        .nav_desk_n ul li a, .nav_desk_n .nav_link_n{
                font-size: var(--fs-st);
                color: #fff;
                width: 100%;
                height: 100%;
                display: block;
                box-shadow: none;
        }
        .changeNavColor_n ul li a, .changeNavColor_n .nav_link_n{
                font-weight: 400;
                font-size: var(--fs-st);
                color: var(--main-color);
                width: 100%;
                height: 100%;
                display: block;
                box-shadow: none;
        }
        .changeNavColor_n {
                background-color: #fff;
                border-bottom: 1px solid #e9e9e9;
                box-shadow: 0px 2px 2px #e9e9e9;
                transition: all .5s ease-in-out;
        }
        .changeNavColor_n ul li:hover {
                border-bottom: 1px solid var(--main-color);
        }
        .nav_desk_n ul li .active{
                color: #98B9F6;
        }
        .nav_desktop_n .nav_list_n {
                display: inline;
        }
        .nav_grid_ne_category {
                display: grid;
                grid-gap: 1rem;
                margin-block: 1rem;
        }

}

@media(min-width: 60rem) {
        .nav_desk_n ul {
              width: 75%; 
        }
}
@media (max-width: 50rem){
      .nav_desk_n {
        display: none;
      }
}