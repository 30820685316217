@media(min-width: 60rem){
        .price-discount{
                background-color: var(--main-color);
                border-radius: 4px;
                color: #fff;
                padding: .3rem; 
                font-weight: 900;
                margin-top: 2.5rem;
        }
}


/* =============================search bar design ============ */

.rating {
        color: var(--golden-yellow);
        padding-inline: 0.1rem;
}
.searchBar .search {
        max-width: 20rem;
        border-radius: 0;
        background-color: transparent;
}

.redirect_to_login_url {
        color: var(--main-color);
        text-decoration: underline;
        font-size: 0.9rem;
        margin-bottom: 1rem;
        cursor: pointer;
}

/* ============ ALERT FOR NON USER BUY ================ */
.no_user_close {
        display: block !important;
}
.no_user_close div {
        min-height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}

.no_user_close div * {
        display: block;
        text-align: center;
}
.no_user_close a {
        text-decoration: underline;
        color: var(--main-color);
        margin-top: 1rem;
        font-size: 0.9em;
}
.no_user_close_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;
        font-size: 1rem;
        padding-bottom: 0.2rem;
        cursor: pointer;
        transition: all .3s ease-in;
}
.no_user_close_btn:hover {
        background-color: rgba(102, 102, 102, 0.068);
}

