

.Footer {
        margin-top: 5rem;
}
.footer_upper {
        background-color: #fff;
        padding: 4rem;
}
.footer_top_grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 2rem;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
}
.footer_grid_item_value {
        text-align: center;
        color: #a1a1a1;

}
.footer_grid_item_value .title {
        margin-block: 1rem;
        /* font-family: 'Montserrat'; */
        font-weight: 500;
        color: black;
}
.footer_grid_item_value .text{
        font-size: 0.9rem;
        line-height: 1.3rem;
}
.footer_grid_item_value .icon {
        font-size: 2.5rem;
        /* padding-block: 1rem; */
}

/* ================FOOTER BOTTOM ================ */

.footer_bottom_grid {
        border-bottom: none;
        grid-template-columns: repeat(5, 1fr);
}
.footer_bottom_grid .icon {
        font-size: 1.4rem;
        padding-right: .5rem;
        color: #000;
}
.footer_bottom_grid .footer_grid_item_value{
        text-align: left;
}
.footer_bottom_grid .desc p {
        margin-bottom: 0.5rem;
}
.footer_bottom_grid a {
        color: #a1a1a1;
}

/* ======================= FOOTER LOWER ============ */

.footer_lower_grid {
        padding-block: 3rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: #9c9c9c;
}
.footer_lower_grid .indi {
        margin-bottom: 2rem;
        font-weight: lighter;
        /* font-size: ; */
        text-transform: capitalize;
}
.indi .desc {
        margin-right: 2rem;
}
.footer_lower_grid .title {
        color: #000;
        margin-bottom: 1rem;
        font-weight: 300;
}
.footer_under {
        background-color: #333333;
        color: #868686;
        font-weight: 200;
        padding-block: 1.5rem;
}

@media(min-width: 50rem){
        .footter_u_mobile{
                display: none;
        }
}
@media(max-width: 50rem){
        .Footer{
                display: none;
        }
}