.earning_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.earning_title h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-right: -8px;
    text-transform: capitalize;
}
.earning_history{
    display: flex;
    justify-content: center;
    /*gap: 20px;*/
    align-items: center;
}
.earning_title a {
    font-size: 0.8rem;
    padding: 10px;
    max-width: 150px;
    background-color: white;
    color: #002b8c;
    border: 1.5px solid #002b8c;
    text-transform: capitalize;
    font-weight: 500;
}
.earning_title a:hover{
    background-color: #002b8c;
    color: white;
}
.d_flex_title {
    display: flex;
    justify-content: space-between;
}
.btn_earning_table {
    font-size: 0.85rem;
    background-color: transparent;
    /*border: 1px solid #cccccc;*/
    border-radius: 30px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 10px;
    color: #5e5e5e;
    font-family: 'Open Sans', sans-serif;
}
.withdraw_btn_flex {
    display: flex;
}
.withdraw_btn_flex button {
    margin: 0px;
    margin-left: 1rem;
}
.withdraw_btn_flex >div {
    height: auto;
    max-height: 1.65rem;
}
.earning_table tr td:nth-child(1){
    width: 13%;
}
.earning_table tr td:nth-child(2){
    width: 30%;
}

.earning_table thead {
    background-color: #efefef;
    text-transform: capitalize;
}
.earning_table thead tr th {
    font-weight: 400;
    font-size: 0.9rem;
}

.no_earnings {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 2rem;
    font-weight: bold;
    width: 320%;
    margin: 0 auto; /* Center if within a container */
}

.table_wrapper {
    width: 100%;
    overflow-x: scroll;
}
.earning_table {
    border-radius: 5px;
    min-width: 600px;
}
.earning_activity {
    padding: 0.05rem 0.8rem 0.1rem;
    border-radius: 4px;
    font-size: 0.75rem;
    max-width: 4.2rem !important;
}
.earning_activity.earn {
    color: green;
    background-color: rgba(0, 128, 0, 0.164);
    border: 1px solid green;
}
.earning_activity.spend {
    color: crimson;
    background-color: rgba(220, 20, 60, 0.151);
    border: 1px solid crimson;
}
.earning_summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
    margin-top: 1rem;
}
.earning_grid_item {
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
.earning_summary h2 {
    font-size: 0.85rem;
    font-weight: 400;
    text-transform: capitalize;
}
.earning_summary .earning_amount {
    font-size: 1.9rem;
    font-weight: 500;
    margin-block: 1rem;
    font-family: 'Montserrat', sans-serif;
}
.earning_grid_item .earning_activity {
    max-width: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 0.3rem;

}
.earning_grid_item .earning_activity span {
    display: block;
    margin: 0rem;
    padding: 0rem;
    padding-right: 0.1rem;
}
.earning_grid_item .earning_activity span:nth-child(1) {
    margin-top: 0.1rem;
    margin-right: 0.2rem;
}
.earning_grid_item .earning_activity span:nth-child(2) {
    margin-top: -0.1rem;
}
.earning_history.filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-top: 1.5rem;
}
.filter_tree_select {
    margin-right: 1rem;
    height: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0rem 1rem;
    outline: none;
    background-color: transparent;
}
.filter_tree_select option {
    padding-block: 0.2rem;
    font-weight: 400;
    text-transform: capitalize;
}


@media(max-width:50rem){
    .earning_page .container {
        margin: 0px;
        padding-right: 0px;
        width: 100%;
    }
    .container .earning_title{
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }
    .container .earning_title .earning_history{
        margin: 0
    }
    .earning_title h2{
        font-size: 1rem;
    }
    .earning_title .btn_earning_table{
        display: none;

    }
    .earning_title a{
        padding: 6px;
        font-size: .7rem;
        margin-right: .4rem;
        border-radius: 4px;
    }

}