.cart {
        display: flex;
        z-index: 1;
        position: relative;
        /* height: 100vh; */
        flex-wrap: wrap;
}
.cart_list_section  {
        width: 100%;
}
.cart .summary, .cart .cartSection, .cartItemsSection {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 2rem 1rem;
        margin-right: 2rem;
        height: auto;
        max-height: 22rem;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.041);
        margin-block: 1rem;
}
.cart .cartItemsSection {
        max-height: 100%;
}

.cart .cartSection, .cartItemsSection {
        width: 96%;
}
.lineCart {
        padding-bottom: 1rem;
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 2rem;
}
.summary_det {
        display: flex;
        justify-content: space-between;
        margin-bottom: .5rem;
        font-size: .8rem;
}
.cart .protect {
        font-size: 0.8rem;
}
.checkout_button{
        width: 100%;
        padding: .5rem 2rem;
        background-color: var(--main-color);
        border-radius: 40px;
        color: #fff;
        font-weight: 600;
        text-align: center;
        margin-top: 1rem;
        cursor: pointer;
}


.cart .icon {
        margin-right: 1rem;
}
.cart .title {
        color: #333333;
        margin-bottom: .5rem;
}

/* ============================= CART ITEM DETAIL =========================== */

.cartItemWrapper {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        padding-block: 0.625rem;
        padding-right: 10px;
        font-size: var(--fs-md);
        min-height: 5rem;
        background-color: #f5f5f5;
}
.cartDescription {
        flex-basis: 200%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        font-size: var(--fs-st);
        font-family: "quicksand";
        color: var(--secondaryColor);
        margin-left: 1rem;
}

.cartImageWrapper {
        padding-inline: 0.625rem;
        margin-left: 1rem;
        width: 50%;
        position: relative;
        overflow: hidden;
}
.cartImageWrapper img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
}

.cart .cartBtn {
         font-weight: 400;
         color: ghostwhite;
        padding: 10px;
        width: 100%;
        background-color: rgba(255, 9, 0, 0.81);
        font-size: 0.8rem;
        border-radius: 5px;
        cursor: pointer;
        
}

.cart .cartBtn:hover{
        background-color: rgba(255, 9, 0, 0.94);
        transition: 0.2s;
}

.cartSaveToLater {
        margin-left: 1rem;
}

.remove {
        display: none;
}
.cart .imgWrapper{
        height: 15rem;
}
.cartPrice {
        visibility: hidden;
}

.cartControl {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
        width: 100%;

}
.cart .desc p{
        text-align: left;
}

.cartItemName,
.cartPrice {
        font-family: "quicksand", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        color: #333333;
        font-size: var(--fs-md);
        font-weight: 500;
        text-transform: capitalize;
  
}

@media(min-width: 40rem){
        .cart {
                flex-wrap: nowrap;
        }
        .cart .cart_summary {
                position: sticky;
                top: 15%;
                right: 0%;
        }
        .cart .summary {
                max-width: 22rem;
                margin-bottom: 0;
        }

        .cartItemWrapper {
                border: 1px solid #16295714;
                background: white;
                max-width: 46rem;
                height: 10rem;
                margin-top: 20px;
        }
        .cartMobile_price {
                display: none;
        }
        .cartPrice {
                visibility: visible;
                font-size: var(--fs-lg);
                text-align: end;
        }
        .cartBtnControl{
                margin: 10px auto;
                display: flex;
                width: 100%;
                justfiy-content: space-between;
        }
        .remove {
                display: block;
                width: 100%;
        }
        .cartBtnControl .remove {
                display: inline;
                background-color: #cf0101;
                color: white;
                padding: 10px 20px;
                font-weight: 400;
                border-radius: 10px;
        }
        .cartBtnControl .remove:hover{
                background-color: #9e0707;
        }
        .cartBtnControl .add {
                display: inline;
                white-space: nowrap;
                background-color: #01cf49;
                color: white;
                padding: 10px 20px;
                font-weight: 400;
                border-radius: 10px;
        }
        .cartBtnControl .add:hover{
                background-color: #079e53;
        }
        .checkoutCondition div {
                padding-top: 0rem;
        }
        .cartItemName {
                font-size: var(--fs-md);
                font-weight: 400;
        }
        
}
@media (max-width: 480px){

        .cart .cartBtn{
                margin-left: 1rem;
                font-size: .7rem;
                padding: 8px;
        }

}
@media (max-width: 639px){

        .cartMobile_price {
                font-weight: 500;
                padding-top: .5rem;
        }
}