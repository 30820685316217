.seller_profile .flex {
  padding-block: 2rem;
}
.seller_profile_main {
  width: 100%;
}
.seller-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -20px auto;
  top: 0%;
}
.datatable-wrapper {
  overflow-x: auto;
}
.content-wrapper {
  max-width: 800px;
  width: 100%;
}
[role="rowgroup"]{
  cursor: pointer;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.datatable-wrapper .active {
  font-weight: 500;
  font-size: 1.1rem;
  color: #1800f4;
}

.create-payment-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-payment-button:hover {
  background-color: #0056b3;
}

.cancel-edit-button {
  padding: 10px 15px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-edit-button:hover {
  background-color: #c82333;
}


.button-wrapper button{
  color: white;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.button-wrapper button.edit-btn{
  background-color: orange;

}
.button-wrapper button.edit-btn:hover{
  background-color: #b97207;
  transition: 0.3s ease-in-out;
}
.button-wrapper button.delete-btn, .proceed{
  background-color: crimson;
}
.deletion-modal-text{
  font-weight: 400;
}
.to-be-deleted-number{
  font-weight: 600;
}
@keyframes shake {
  0% { transform: translateX(0) rotateY(0deg); }
  25% { transform: translateX(-5px) rotateY(180deg); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0) rotateY(20deg); }
}
.button-wrapper .shake {
  animation: shake 0.5s ease-in-out; /* Durée et type d'animation */
}
.button-wrapper button.delete-btn:hover, .proceed:hover{
  background-color: rgb(143, 7, 35);
  transition: 0.3s ease-in-out;
}
.button-wrapper button.create-btn{
  background-color: #006b23;

}
.button-wrapper button.create-btn:hover{
  background-color: #00561d;
  transition: 0.3s ease-in-out;
}
select option {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
}
.payment_method_account_name {
  display: flex;
  justify-content: center; /* Centers the children horizontally */
  align-items: flex-start; /* Centers the children vertically */
  gap: 15px; /* Space between the payment method dropdown and account name input */
  width: 100%;
}

.payment_method_selector,
.account_name_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.payment_method_selector {
  margin-top: 15px;
}

.panel-seller-profile .close{
        position: absolute;
        top: 1%;
        right: 4%;
}

.primary_checkbox {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.primary_checkbox label {
  margin-top: 6px;
  font-weight: 600;
}

.primary_checkbox input {
  margin-right: 10px;
}

body {
  font-family: "Open Sans", sans-serif;
}

.payment_details_wrapper {
  padding: 5px 20px;
}

.payment-info {
  padding: 5px 10px;
  background-color: rgb(222, 237, 255);
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
}

.mobile_money_fields,
.bank_fields {
  margin-bottom: 20px;
}
.field .account_name_field {
  margin-top: 0px;
}
.field {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.field input::placeholder {
  font-size: 0.9rem;
  color: #888;
  font-weight: 400;
}
.profile_control_btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.bank_fields {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Creates a two-column grid */
  gap: 15px;
}

.bank_fields .field {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.select-dropdown {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 3rem;
  padding: 8px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  color: #333;
  width: 100%;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}

.seller_profile_right {
  width: 100%;
  max-width: 30rem;
}

.image_and_btn_section {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: flex-end;
  width: auto;
  padding: 0;
}
.panel-seller-profile {
  padding: 7rem 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  overflow-y: auto;
}

.panel-seller-profile::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.panel-seller-profile {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.instructions-seller-profile {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  position: relative;
  text-align: center;
  margin-top: 80px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
.edit_icon {
  position: relative;
  left: -9%;
  margin-bottom: 0.5rem;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: #fff;
  color: green;
  border: 1px solid green;
  cursor: pointer;
  font-weight: 500;
  width: 1.5rem;
  height: 1.5rem;
}
.seller_profile_right .profilePic {
  border: 2px solid rgb(199, 199, 199);
  width: 6rem;
  height: 6rem;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
  position: relative;
}
.seller_profile_right .profilePic::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "Edite";
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  pointer-events: none;
}
.seller_profile_right .profilePic:hover::after {
  background-color: #0003;
  opacity: 1;
}
.name_editing_form {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
}
.name_editing_fom_item {
  width: auto;
}
.seller_profile .profile_text {
  margin-top: 1rem;
}
.seller_profile .profile_text p {
  margin-block: 0.3rem;
  text-align: center;
  /* font-weight: 300; */
}
.email_value {
  font-weight: 400;
}

.name_and_edit_field {
  top: 1rem;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
}
.edit_icon:hover {
  background-color: #dddddd;
  color: rgba(100, 100, 100, 0.9);
}
.editing_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_text .save_btn {
  margin-left: 0.3rem;
}
/* ======================= THE OTHER SIDE ============== */
.seller_profile_head {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 50rem;
  margin: auto;
  margin-block: 2rem;
}
.seller_profile_head > div {
  text-align: center;
  border-bottom: 1.6px solid var(--gray);
  width: 100%;
  transition: all 0.3s ease-in-out;
  cursor: default;
}
.seller_profile_head .active,
.seller_profile_head > div:hover {
  border-bottom: 2.5px solid tomato;
}
.seller_profile_head_one {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.seller_profile_head_one .desktop {
  display: none;
}
.seller_dashboard_mobile,
.mobile_seller_dashboard {
  font-size: 0.85rem;
}
.seller_dashboard_mobile {
  margin-right: 0.4rem;
}
.icon_section_title_edit {
  font-size: 1.1rem;
  /* margin: auto;
        padding: 0 auto; */
}

.icon_person {
  color: blue;
}
.icon_bank {
  color: rgb(182, 126, 29);
}
/* ================= PERSONAL INFORMATION ======================= */
.seller_profile_wrapper {
  display: grid;
  margin-bottom: 1rem;
  grid-gap: 1rem;
}
.seller_profile_wrapper .profile {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}
.seller_title {
  font-weight: 400;
  margin-right: 1rem;
  width: 50%;
  font-size: 0.85rem;
}
.profile_about .seller_title {
  margin-bottom: 1rem;
}
.input_seller_text {
  width: 100%;
  padding: 0.3rem 0.9rem;
  border: 1px solid var(--gray);
  border-radius: 4px;
}

.select_bank {
  border-right: none;
  border-top-right-radius: none;
  border-bottom-right-radius: none;
}

.bank_dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chevronDown {
  border: 1px solid var(--gray);
  border-left: none;
  padding: 0.44rem;
  background: #fff;
  width: auto;
  height: auto;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: -5px;
}

.profile_about {
  margin-top: 2rem;
  border-top: 1px solid var(--gray);
  padding-top: 1rem;
}

/* ============== BUTTON ============== */
.button_payment_request {
  display: flex;
  justify-content: end;
}
.profile_control_btn {
  outline: none;
  border: none;
  color: #fff;
  background-color: var(--main-green);
  margin-block: 1rem;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  float: right;
  font-size: 1rem;
  min-width: 14rem;
  min-height: 2.5555rem;
}
.profile_control_btn:hover {
  background-color: rgb(3, 87, 23);
  transition: all 0.2s ease-in-out;
}

.profile_control_btn .loading svg {
  width: 1rem;
  margin-left: 0.7rem;
  padding-top: 0.5rem;
}
.profile_about_text_input {
  outline: none;
  border: 1px solid var(--gray);
  width: 100%;
  height: 12rem;
  resize: none;
  padding: 0.7rem;
  border-radius: 4px;
}
.profile_about_text_input::-webkit-scrollbar {
  display: none;
}
/* ================ PAYMENT METHODS ================== */
.error_input_msg {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(247, 242, 242, 0.9);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.12);
  width: fit-content;
  border-radius: 3px;
}

@media (min-width: 50rem) {
  .seller_profile .flex {
    display: flex;
    gap: 1rem;
  }
  .seller_profile_main {
    padding-block: 1rem;
  }
  .seller_profile_right .profilePic {
    margin: auto 0;
  }
  .seller_profile_right {
    width: 100%;
    max-width: 20rem;
    border: 1px solid rgba(100, 100, 100, 0.2);
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
    height: 100%;
    /* max-height: 30rem; */
  }
  .seller_profile .profile_text {
    text-align: center;
  }
  .mobile_seller_dashboard {
    display: none;
  }
  .seller_profile_head {
    margin-top: 0rem;
  }
  .seller_profile_head_one .desktop {
    display: inline;
    font-weight: initial;
  }
  .seller_profile_wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  .seller_profile_dashboard_personal_info {
    margin-top: 5rem;
  }
  .seller_title {
    width: 30%;
  }
}

@media (max-width: 50rem) {
  .form_authenticate_user_seller .user_auth_popup {
    width: calc(96% - 2rem);
    margin: auto;
    margin-left: calc(3rem);
  }
}
