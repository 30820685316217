
.onboard_landing {
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    min-height: 80vh;
    position: relative;
}

.seller_onboarding h3, .seller_onboarding h2 {
    text-align: left;
    font-weight: 500;
    margin-bottom: 1rem;
}
.bg_vid {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
}

#myVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

  .onboard_landing .bg_vid::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
      background: linear-gradient(to right, rgba(0, 0, 16, 0.7), rgba(0, 0, 48, 0.6));
    pointer-events: none;
  }
.onboard_landing .container {
    text-align: center;
    color: #fff;
    flex-direction: column;
    position: relative;
    z-index: 2;
}
.onboard_title {
    font-size: 3.5rem;
    margin-bottom: 2rem;
    font-weight: 900;
}
.onboard_desc {
    max-width: 1200px;
    line-height: 2rem;
    text-align: left;
    color: ghostwhite;
    margin: auto;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}
.d_flex {
    display: flex;
    margin-top: 35px;
    justify-content: flex-start;  /* Centers the buttons within the div */
    align-items: center;      /* Aligns items vertically */
    gap: 10px;                   /* Removes any spacing between them */
}

.d_flex a {
    margin-top: 16px;
    padding: .45rem 5rem;
    background-color: white;
    color: #0056d6;
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;/* Ensures no additional margin is applied */
}
.sellerBtn {
    padding: .6rem 5rem;
    background-color: #0056d6;
    color: #fff;
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submitBtn:hover{
    background: #002b8c;
    transition: .1s ease-in-out;
}

.sellerBtn:hover{
    background: #002b8c;
    transition: .1s ease-in-out;
}
.d_flex a:hover{
    background: #0056d6;
    color: ghostwhite;
    transition: .1s ease-in-out;
}
.onboarding_subtitle {
    font-size: 2rem;
    padding: 2rem 0;
}





.join_community {
    background-color: #fff;
}


.how_it_works {
    background-color: #f7f7f7;
    padding: 2rem 0;
}
.how_it_works_grid {
    font-size: calc(0.1rem + var(--fs-md) );
    display: grid;
    grid-gap: 2rem;
    text-align: center;
}

.q_and_a {
    background-color: #fff;
    margin-top: 2rem;
}
s
.q_and_a .dropdown {
    position: relative;
    padding: 1rem 2rem;
    border-radius: 8px;
    
    transition: all .3s ease-in-out;
}
.q_and_a .dropdown:hover {
    /* padding: 1rem 2rem; */
}
.q_a_a_wrapper .dropdown {
    margin-block: 1rem;
}
.q_and_a .answer {
    height: 0px;
    overflow: hidden;
    max-width: 80%;
    font-size: 0.8em;
    position: relative;
}
.q_and_a .question {
    cursor: pointer;
    padding-bottom: 0.8rem;
    transition: all .3s ease-out;
}
.q_and_a .question:hover + *, .q_and_a .dropdown:hover *{
    transition: all .4s ease-out;
    height: 3rem;
}



/* ======= USER STORIES ================ */
.onboading_stories {
    margin-block: 1.5rem;
}
.grid_stories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1rem;
    min-width: 900px;
}
.onboarding_slider {
    overflow-x: scroll;
}
.flex_stories {
    display: flex;
    height: 15rem;
    background-color: #fff;
}
.flex_stories >div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
}
.onboading_stories .img_user {
    overflow: hidden;
}
.onboading_stories .img_user img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.quote_arrow {    
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent; 
    border-right:20px solid #fff; 
    transform: translateX(-100%);
}
.quote_right {    
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent; 
    border-left:20px solid #fff; 
    transform: translateX(100%);
    z-index: 10;
}
.last .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.quote p{
    width: 90%;
}
.last h3 {
    margin-bottom: 0px;
    margin-right: 2rem;
}
.last .start_selling {
    background-color: var(--main-color);
    border-radius: 300px;
    padding-inline: 2rem;
    margin: 0px !important;
    font-weight: 500;
}
.quote {
    flex-direction: column;
}
.quote_name {
    font-size: 0.8em;
    font-weight: 500;
    margin-top: 1rem;
}
.quote_text {
    font-weight: 300;
    font-style: italic;
}

@media(min-width: 50rem){
    .how_it_works_grid {
        grid-template-columns: repeat(3, 1fr);
    }
}