.user_auth_popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2001;
}
.user_auth_popup .shadow_user {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.192);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
}
.auth_u {
        width: 95%;
        margin: auto;
        max-width: 35rem;
        background-color: #fff;
        padding: 2rem 1rem;
        border-radius: 8px;
        position: relative;
}

.authForm {
        margin-block: 2rem;
}
.loginModal_head {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        position: relative;
}
.loginModal_head .title {
        font-size: .9rem;
        font-weight: 600;
        margin-right: 1rem;
        padding: .5rem .8rem;
        transition: all .3s ease-in-out;
        cursor: pointer;
}

.closeModal {
        position: absolute;
        top: 0;
        right: 0;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
}

.inputContainer {
        width: 100%;
        margin-bottom: 1.5rem;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        overflow: hidden;
}
.inputContainer input{
        width: 100%;
        height: 100%;
        height: 2rem;
        padding-left: 1rem;
        padding-block: 1.3rem;
}
.inputContainer select{
        width: 100%;
}
.show_password_icon {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 200;
        padding: 0.5rem;
        padding-bottom: 0.2rem;
}
.show_password_icon * {
        font-size: 1.2rem;
}


.loginBtn input, .loginBtn button{
        width: 100%;
        margin-bottom: 1.5rem;
        background-color: var(--main-color);
        border-radius: 20px;
        color: #fff;
        font-weight: 400;
        padding-block: .7rem;
        outline: none;
        border: none;
}
.loginBtn .submit{
        background-color: var(--main-color);
        
}
.buttonWrapper{
    display: flex;
    margin: auto;
    position: relative;
}


/* ================LOG OUT =============== */
.logout_modalBtn_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
}
.logout_modalBtn_wrapper > div{
        padding: .4rem .8rem;
        text-transform: capitalize;
        margin-right: 1rem;
        border-radius: 5px;
        cursor: pointer;
}
.cancel_logoutBtn {
        border: 1px solid #3333335d;
}
.modal_logoutBtn {
        background-color: crimson;
        color: #fff;
}
@media(min-width: 50rem){
        .authForm {
                max-width: 22rem;
                margin: auto;
                margin-top: 3rem;
                margin-bottom: 3rem;
        }
}