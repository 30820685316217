
.recommendation_and_conclusion{
        display: block;
        position: relative;
}
.pined_category {
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 2rem 1rem;
        margin-right: 2rem;
        height: auto;
        max-height: 26rem;
        position: sticky;
        top: 20%;
        box-shadow: 0px 0px 2px 2px rgba(189, 189, 189, 0.199);
        margin-block: 1rem;
        overflow-y: scroll;
}

.pined_category::-webkit-scrollbar {
        width: 10px;
        border-radius: 8px;
}

.pined_category ul {
        list-style-type: square;
}
.category_item {
        display: block;
        margin-top: .7rem;
        margin-left: 1rem;
        font-size: var(--fs-md);
        position: relative;
        /* font-weight: 800; */
}
.category_item a {
        display: flex;
}
.category_item .drop_cat_list {
        height: 0px;
        overflow: hidden;
        transition: all .3s ease;
}
.category_item:hover .drop_cat_list {
        height: 100px;
}
.cat_dropdown_indicator span {
        display: block;
}
.category_item:hover .cat_dropdown_indicator {
        margin-top: 0px;
}
.category_item:hover .cat_dropdown_indicator span {
        transform: rotate(90deg);
        transition: all 0.5s ease;
}

.cat_dropdown_indicator {
        margin-left: 1rem;
        margin-top: 0.1rem;
}
.productGridItem {
        background-color: #fff;
        padding: 2rem;
        border-radius: 5px;
}
.detail-review {
        margin-bottom: 2rem;
}

.productReviewLink {
        margin-inline-end: 1rem;
        color: #0f7886;
        border-bottom: 1.4px solid #0f7886;
        padding: .5rem;
        font-size: .775rem;
        cursor: pointer;
}

.productReviewLink.active {
        color: #0f7886;
        border-bottom: 1.4px solid #0f7886;
}

.productReviewLink:not(.active) {
        color: #a8a7a7;  /* Inactive state color */
        border-bottom: 1.4px solid #a8a7a7;  /* Inactive state border */
}


/* ====================DETAIL SECTION ========================  */
.productDetailList{
  padding-block: 2rem;
}
.product_detail_top_grid .description,
.itemDescription > p {
  line-height: 2rem;
  font-size: var(--fs-md);
  text-align: justify;
}

.productDetailListItem{
  border-bottom: 1px solid #16295724;
  display: flex;
  justify-content: space-between;
  padding-block: .7rem;
}
.productDetailListItem p{
        font-size: var(--fs-md);
        color: #505051de;
        font-weight: 400;
}

.productDetailListItem p:nth-child(1){
  font-weight: bold;
}
/* ====================REVIEW SECTION ========================  */
.reviews{
  margin-top: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
}
.reviews .title{
  margin-bottom: 0.2rem;
}
.review{
  border-bottom: 1px solid #16295714;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: .6rem;
  margin-bottom: 2rem;
}
.review .profile{
  margin-right: 2rem;
}
.profilePic{
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #555;
  margin-bottom: .2rem;
}

/* ================ RECOMMENDATION SECTION =================== */
.recommendation {
        background-color: #fff;
        padding: 2rem;
        border-radius: 5px;
        margin-top: 3rem;
}
.recommendation .title {
        display: inline;
        margin-right: 1rem;
}

.loadingrecommendations {
        width: 100%;
        min-height: 5rem;
        border-radius: 4px;
        position: relative;
        overflow-x: scroll;
}
.loadingrecommendations::-webkit-scrollbar {
        display: none;
}


.recommendation_overflow {
        min-width: 40rem;
}
.header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 4rem;
}
.header h2 {
        font-weight: 500;
        margin-bottom: 1rem;
        width: 100%;
        font-size: 1.1rem;
        text-wrap: nowrap;
}


@media (min-width: 50rem) {
        .recommendation_and_conclusion{
                display: flex;
        }
        .recommendation_and_conclusion .desc_right {
                width: 100%;
        }
        .productGridItem {
                width: 100%;
                grid-column: auto /span 6;
        }
        .product_detail_top_grid .content {
                grid-column: auto /span 7;
        }
        .openProductGridWrapper .fixedFlex{
                padding: 0rem 4rem;
                position: sticky;
                top: 10%;
                height: auto;
                max-height: 20rem;
                max-width: 34rem;
                border: none;
        }
        .desktopTitle {
                display: block;
                text-align: center;
                margin: 1rem;
        }
                
        .pined_category {
                max-width: 28rem;
                position: sticky;
                margin-top: 0;
        }
        .product_detail_top_grid {
                position: relative;
                height: auto;
        }
        .product_detail_top_grid > .images {
                position: sticky;
                /*top: 20%;*/
                max-height: 37rem;
        }
        
}
@media (max-width: 50rem){
        .itemDescription > p, .review p{
                font-size: .8rem;
                line-height: 1.4rem;
        }
        .remove_mobile {
                display: none;
        }
}
