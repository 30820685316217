.ebook_n {
        margin-block: 3rem;
}
.ebook_grid_n {
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 1rem;
}


.ebook_list_n {
        color: #777;
        grid-column: 1 / span 4;
        border: 1px solid #9999992f;
        padding: 2rem 1.5rem;
        border-radius: 5px;
        margin-top: 1rem;
        position: static;
        top: 2%;
        background-color: #fff;
}
.ebook_list_n .title {
        margin-bottom: 1rem;
        font-size: 1rem;
}
.search_n_user {
        position: relative;
}
.search_n_user input {
        border-radius: 20px;
        background-color: transparent;
}
.search_n_user .ais-SearchBox-loadingIndicator,
.mobile_search_user .ais-SearchBox-loadingIndicator {
        display: none;
}
.svg_login {
        margin-block: 1rem;
}
.ebook_subgrid_n {
        grid-column: auto / span 7;
}
.ebook_subgrid_n .home_grid{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.ebook_n .ais-SearchBox-reset, 
.ebook_n .ais-SearchBox-resetIcon,
.ais-SearchBox-reset{
        display: none;
}
.ebook_n .ais-SearchBox-submit,
.mobile_search_user .ais-SearchBox-submit {
        position: absolute;
        top: calc(0px + 1px);
        right: 0%;
        border-radius: 2rem;
        margin: 0rem;
        height: calc(100% - 2px);
        transform: translate(-2px, 0%);
        background-color: #fff;

}
.ebook_n .ais-SearchBox-submit svg {
        width: 0.85rem;
        height: 0.85rem;
}
.ebook_n .ais-SearchBox.search_n_user {
        max-width: 20rem;
        background-color: transparent;
}
.ebook_n .ais-SearchBox .ebook_n .ais-SearchBox-input {
        background-color: transparent;
}
.ebook_list_n {
        overflow-y: scroll;
}
.ebook_list_n::-webkit-scrollbar {
        display: none;
}
.ais-Hits-list {
        list-style: none;
}
.hit_grid {
        display: flex;
        border-bottom: 1px solid #eee;
        margin-bottom: 0.5rem;
        padding-bottom: 0.3rem;
        padding-inline: 0.5rem;
        cursor: pointer;
}
.hit_grid:hover .hit_auth, 
.hit_grid:hover .hit_title {
        color: rgb(86, 157, 204);
}
.hit_item_image {
        width: 30%;
        max-width: 3rem;
        height: 3.4rem;
        overflow: hidden;
        border: 1px solid #eee;
        margin-right: 1rem;
        position: relative;
}
.hit_item_image .img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        
}
.hit_title {
        font-size: var(--fs-st);
        transition: all .3s ease-in-out;
        font-weight: 500;
}
.hit_auth {
        transition: all .3s ease-in-out;
        font-size: var(--fs-sm);
}
.pagination_btn_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
}
.pagination_btn_wrapper a{
        margin-inline: 0.3rem;
        background-color: var(--main-color);
        color: #fff;
        padding: 0.3rem 1rem;
        border-radius: 4px;
}
.pagination_btn_wrapper p {
        font-weight: 600;
        padding-inline: 0.6rem;
}
.pagination_btn_wrapper .deactivate_btn {
        cursor: not-allowed;
        background-color: #0000807c;
}
.pagination_btn_wrapper .pagination_nom_desktop_ {
        display: none;
}
@media(min-width: 60rem){
        .ebook_grid_n {
                display: grid;
        }
        .ebook_list_n .title {
                font-size: 1.4rem;
        }
        .ebook_list_n {
                position: sticky;
                max-height: 50rem;
        }
        .pagination_btn_wrapper .pagination_nom_mobile_ {
                display: none;
        }
        .pagination_btn_wrapper .pagination_nom_desktop_ {
                display: block;
        }
}

.no_books_cat_section {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 40%;
        width: 100%;
        margin-top: 20px;
        padding: .5rem;
        background-color: rgba(200, 200, 250, .8);
        animation: 3s linear infinite alternate;
}
.no_books_cat_section .message{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 1rem;
}
.no_books_cat_title{
        font-size: 1.2rem;
        font-weight: 500;
        color: #555;
}
/* @keyframes no_book_animation{
        0%{
                background-color: ;
        }
} */
.no_books_cat_text{
        font-size: 1rem;
        font-weight: 500;
        color: #555;
        text-align: left;
        text-transform: capitalize;
        max-width: 20rem;
}
.illustrative_cat_image{
        width: 18rem;
        height: 13rem;
}
