
.carouselContainer{
        /* width: 80%; */
        max-width: 60rem;
        margin: auto;
}
.popular_review .imgWrapper {
        width: 4rem;
        height: 4rem;
        background-color: #42a4ff46;
        border-radius: 50%;
}
.popular_review {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 20rem;
        margin-inline: 1rem;
        /* background-color: blue; */
}
.popular_review .popular_reviewText {
        width: calc(100% - 5rem);
        text-align: left;
}
.quote_body {
        width: 100%;
}
.quote_body p{
        margin: 0;
        padding: 0;
}
.quote_body .quote_icon {
        font-size: 2rem;
        color: #42a4ff46;
}
.carousel-status {
        display: none !important;
}
.quote_review_name {
        font-size: var(--fs-st);
        font-weight: 400;
        text-align: center;
        margin-top: .7rem;
}
.carousel .control-dots .dot {
        background-color: #ccc;
        opacity: 0.7;
}

.carousel .control-dots .dot.selected {
        background-color: var(--main-green);
        opacity: 1;
}

/* ========================== CATEGORY CAROUSEL =================== */
.carouselContainer_category {
        width: 100%;
}
.carosel {
        width: 90%;
        overflow: hidden;
        position: relative;
        border-radius: 10px !important;
        text-transform: capitalize;
        font-family: "quicksand", sans-serif;
}
.carosel .wrapper {
        height: 12rem;
}

.carosel .categoryImage {
        height: 100%;
}

.carouselText {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #1a1a1aab;
        color: #fff;
        font-weight: 500;
        font-family: 'Montserrat';
        padding: 1rem;
        transition: all .5s ease-in-out;
        cursor: pointer;
}
.carouselText:hover{
        background-color: #1a1a1a21;
}

.carouselWrapper .carosel .wrapper{
  height: 75%;
  width: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.landing_category_scroll {
        height: 100%;
        margin-bottom: 1rem;
}
@media(min-width: 50rem){
        .carosel .wrapper {
                height: 16rem;
        }
        .carosel .categoryImage {
                width: 100%;
                height: 100%;
                object-fit: cover;  
                object-position: center; 
        }

        .quote_body {
                width: 80%;
        } 
        .quote_body .quote_icon {
                font-size: 3rem;
        } 
        .popular_review .imgWrapper {
                width: 7rem;
                height: 7rem;
        }
        .popular_review .popular_reviewText {
                width: calc(100% - 10rem);
        }
        .quote_review_name {
                font-size: calc(var(--fs-st) + 0.2rem);
                font-weight: 400;
                text-align: left;
        }
}