.topLinks li{
        display: inline;
        font-size: .83rem;
        font-weight: 300;
        text-transform: capitalize;
        padding-left: .5rem;
        padding-right: .5rem;
        border-right: 1px solid #ddd;
        margin-right: .4rem;
}

.topLinks li:last-child{
        border: none;
}

.linkTopWrapper{
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        position: relative;
}
.linkTopWrapper .linkTop{
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        white-space-collapse: preserve;
        text-wrap: nowrap;
}
.flagSpace {
        margin-right: 0.2rem;
}
.dropdownNav {
        position: absolute;
        top: 100%;
        left: -25%;
        width: 150%;
        min-width: 10rem;
        height: auto;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.082);
        z-index: 12;
        background-color: #fff;
        cursor: default;
        transition: all .5s ease-in-out;
        visibility: hidden;
        height: 0px;
        /* overflow: hidden; */
        opacity: 0;
}
.dropdownNav .dropdownNav-item {
        font-weight: 500;
        cursor: pointer;
        padding: .7rem .9rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.103);
        transition: all .4s ease-in-out;
}
.dropdownNav .dropdownNav-item span{
        font-weight: 500;
}
.dropdownNav .dropdownNav-item:hover {
        background: rgba(0, 0, 0, 0.068);
}
.dropdownNav .subtitle {
        margin-inline: .5rem;
        margin-top: 1rem;
        font-family: 'Montserrat';
        text-transform: lowercase;
        font-weight: 500;
}
.nav_selection_list {
        margin-inline: 0.5rem;
        position: absolute;
}
.dropdownNav:hover .dropdownNav,
.dropdown_anchore:hover > .dropdownNav{
    visibility: visible;
    height: auto;
    opacity: 1;
}
.subDropDown {
        margin-top: 0.7rem;
        margin-bottom: 1rem;
}

.blacktext {
        padding-left: 1rem;
}

.linkTopWrapper .iconB{
        margin-top: .3rem;
        margin-left: .7rem;
        color: #474747;
}
.linkTopWrapper .iconApp{
        margin: 0 auto;
        font-size: 1.2rem;
        /* margin-top: 0.1rem; */
        margin-inline: 10px;
        transform: translateY(10%);
}

nav .top{
        padding: 0;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #eee;
}
.topLinks{
        margin: 0;
        padding: .5rem;
}
.nav-main{
        display: flex;
        padding-top: 1.5rem;
        align-items: center;
        justify-content: space-between;
}
.nav-main .logo-base{
        font-size: 1.8rem;
        
}
.right{
        /* width: 7rem; */
        /* margin-inline-start: 3rem; */
        margin-right: auto;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        height: 75%;
}
.right a{
        display: flex;
        align-items: center;
}

.searchWrapper {
        width: 90vw;
}
.cartBtn {
        /* color: red; */

        width: auto;
        margin-left: 3rem;
        border-radius: 50%;
}
.cardBtn:hover{
        color: red;
}
.searchWrapper .search{
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid var(--main-color);
        max-width: 40rem;
        border-radius: 999px;
}
.searchWrapper .search .searchIcon{
        background: var(--main-color);
        color: #fff;
        width: 40px;
        justify-content: center;
        align-items: center;
        display: flex;
        transform: translate(0);
        margin: 0;
        top: 0%;
}
.shoppingGlyphe{
        color: #ffffff;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: var(--main-color);
        padding: .4rem;
        padding-left: .5rem;
        padding-right: .5rem;
        margin: auto;
        justify-content: center;
        align-items: center;
}

.second-menu{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-left: 20px;

}

/* svg[Attributes Style] {
        stroke: currentcolor;
        fill: currentcolor;
        stroke-width: 0;
        height: 1em;
        width: 1em;
    } */
.searchResults, .search_results_mobile {
        position: absolute;
        background-color: #fff;
        box-shadow: 2px 2px 4px rgba(110, 110, 110, 0.151);
        border: 1px solid #eee;
        width: 100%;
        max-width: 500px;
        max-height: 80vh;
        border-radius: 5px;
        padding: 0.5rem;
        padding-top: 1rem;
        overflow-y: scroll;
}
.searchResults::-webkit-scrollbar {
        display: none;
}
.search_results_mobile {
        max-width: 300px;
        font-size: 0.8em;
        max-height: 80vh;
}
.search_result_mobile {
        height: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.7rem;
        padding-block: 0.2rem;
}
.search_result_list {
        display: flex;
        margin-bottom: 1rem;
        cursor: pointer;
}
.search_result_list * {
        pointer-events: none;
}
.search_result_list .result_img {
        width: 2.2rem;
        height: 3rem;
        border-radius: 4px;
        margin-right: 1rem;
        overflow: hidden;
        position: relative;
        border: 1px solid #eee;
}

.search_result_list .result_img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;

}
.search_result_list .result_desc {
        font-size: 0.85em;
        width: calc(100% - 4rem);
}
.search_result_list .result_desc p:nth-child(2){
        max-height: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}
.user_main_hit_shadow {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
}
.nav-main .cartBtn {
        position: relative;
} 
.nav-main input {
        border-radius: 0px;
}
.cart-count {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        color: #fff;
        transform: translate(90%, -60%);
        font-size: 1rem;
        font-weight: 500;
}




@media(max-width: 60rem){
        nav {
                display: none;
        }
}