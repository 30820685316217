.landing_explore.about_expolore {
    background-image: linear-gradient(#00008099, #00008099), url("../../../Assets/img/about/tech.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 466px;
}

.submit_home_contact_form{
  background-color: green;
}
 
.about_container{
  align-items: center;
  justify-content: center;
  margin: auto;
}
.about_page{
  background-color: #fff;
}
.about_container h1{
  text-align: center;
  font-weight: 700;
  letter-spacing: 121;
  font-size: 30px;
}
.about_container h1 span{
  padding: 10px 30px;
  font-weight: 700;
  color: #000080;
  background-color: #fff;
  border-radius: 5px;
}

.about_container p{
  max-width: 800px;
  text-decoration: none;
  text-align: center;
  margin-top: 10px;
  line-height: 24px;
  font-size: 18px;
}

.landing_page_about_us_section {
    /* background: linear-gradient(var(--light-accent-color), var(--main-color)); */
    background: linear-gradient(to right, #011b33 0.4%, #000080 90%, var(--main-color));
}
.about_container p a:hover{
     text-decoration: underline;
    cursor: pointer;
}


.landing_page_about_us_section {
    /* background: linear-gradient(var(--light-accent-color), var(--main-color)); */
    background: linear-gradient(to right, #002d46 0.4%, #000080 90%, var(--main-color));
    margin: 50px 0;
    /* background-color: #000080; */
    height: 400px;
    overflow: hidden;
}

.about_page_break{
  font-weight: 700;
  font-size: 30px;
  border-radius: 5px;
}
 
 
.landing_page_about_us_section h1 span{
    color: var(--main-color);
    font-weight: 700;
    background-color: #fff;
    padding: 5px 10px;
}
.landing_page_about_us_section h1{
  line-height: 50px;
}

.landing_page_about_us_section * {
  color: white;
  font-weight: 700;
}
.landing_page_about_us_section h2 {
    color: #fff;
    font-weight: 700;
}



.about_contact_form {
    margin-top: 5rem;
}
.landing_page_about_us_section .list_text {
    font-size: 0.9rem;
    margin: 10px 0;
}

.contact_input {
    width: 100%;
    margin: 10px 0;
    border: none;
    border-radius: 10px;
    background-color: #fff;
    height: 3rem;
    position: relative;
    overflow: hidden;
}
.contact_input input {
    color: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    padding-left: 1.1rem;
    font-weight: 500;
}
.submit_home_contact_form {
    background-color: var(--gray);
    color: white !important;
    cursor: pointer;
    transition: all .3s ease-in-out;
    margin-top: 0px !important;
}
.submit_home_contact_form:hover {
    background-color: green;
}
  
/* ========= ADVERTISEMENT SECTION  */
.advertisement-section {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
  }
  
.advertisement-slider-container {
    overflow: hidden;
    max-width: 900px;  /* Reduce the overall size */
    margin: 0 auto;    /* Center the container */
}

.advertisement-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.advertisement-slide {
    flex: 0 0 100%;
    display: flex;
    background: linear-gradient(85deg, rgba(3, 3, 173, 0.719), var(--main-color));
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 400px;  
}

.ad-image {
    flex: 0 0 40%;
    padding: 20px;
}

.ad-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.ad-content {
    flex: 0 0 60%;
    padding: 20px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 1.2rem; 
    text-align: center;
    font-weight: 800;
}

.ad-nav-button {
    position: absolute;
    top: 50%;
    width: 3rem;
    height: 3rem;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    z-index: 10;
    border-radius: 50%;
}

.ad-nav-button.prev {
    left: 10px;
}

.ad-nav-button.next {
    right: 10px;
}
.ad-nav-button * {
    margin-top: 0.3rem;
}

@media(min-width: 60rem) {
    .about_section_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .about_section_flex .lists {
        width: 60%;
    }
    .about_contact_form {
        width: 50%;
        padding: 5rem;
        margin-top: 0px;
    }
}


/* ======= WHAT WE DO SECTION  */
.WhatWeDoSection {
    width: 90%;
    max-width: 80rem;
    text-align: start;
    margin: auto;
    padding-block: 3rem;
    height: auto;
}

.blog_section h2,
.landing_page_location_section h2,
.WhatWeDoSection h2 {
    color: var(--secondary-color);
    font-weight: 900;
    font-size: 30px;
}
.WhatWeDoSection h2.section_title{
  text-align: center;
}
.WhatWeDoSection p {
    font-size: 1rem;
    margin: 1rem auto;
    max-width: 40rem;
    margin-bottom: 100px;
}
.card-grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);  
  grid-template-rows: repeat(2, auto);    
  gap: 5px;  
}
.card-item{
  margin-top: 40px;
  color: #000;
  width: 360px;
  height: 320px;

  padding: 30px 20px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.card-item:hover{
  cursor: pointer;
  transform: scale(1.05);
}
.card-grid a:hover{
  text-decoration: none;
  color: inherit;
}
.card-item img{
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}
.card-item h2{
  font-size: 20px;
  font-weight: 500;
}
.card-item p{
  font-size: 13px;
}

.landing_page_location_section h2 {
  margin-top: 5rem;
  text-align: center;
  margin-bottom: 1rem;
}
/* ==============END OF ABOUT SECTION */

  
  .control-dot {
    width: 5px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    /* border-radius: 50%; */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .control-dot.active {
    background-color: white;
  }


.location_google_map{
    padding-right: 30px;
    padding-left: 30px;
}

  /* =========MEDIA QUERIES */
  @media (max-width: 768px) {
    .about_page{
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        overflow-x: hidden;
    }
    .WhatWeDoSection h2 {
        font-size: 1.75rem;  
    }

    .WhatWeDoSection p {
        font-size: 0.875rem; 
        margin-bottom: 50px;
    }

    .card-grid {
        grid-template-columns: 1fr; 
    }

    .card-item {
        width: 100%; 
    }

    .card-item h2 {
        font-size: 1.125rem; 
    }

    .card-item p {
        font-size: 0.75rem; 
    }

    .landing_explore.about_expolore {
        height: auto;  
        padding: 2rem;
    }

    .about_container h1 {
        font-size: 1.75rem;
        letter-spacing: 1px;
        margin-top: 50px;
    }

    .about_container p {
        font-size: 1rem;
        line-height: 1.4;
    }

    .landing_page_about_us_section {
        height: auto;
        padding: 3rem 1rem;
    }

    .about_page_break {
        font-size: 1.75rem;
    }

    .landing_page_about_us_section h2 {
        font-size: 1.5rem;
    }
}

/* Media Queries for very small screens */
  
@media (max-width: 550px) {
    .about_page{
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
    }
    .WhatWeDoSection h2 {
        font-size: 1.5rem;  
    }

    .WhatWeDoSection p {
        font-size: 0.75rem;  
    }
    .landing_explore.about_expolore {
        padding: 1rem;
        background-position: center center;
    }
    .section_title{
        font-size: 1.5rem;
        text-align: center;
    }

    .about_container h1 {
        font-size: 1.5rem;
        margin-top: 50px;
    }

    .about_container p {
        font-size: 0.875rem;
        line-height: 1.3;
    }

    .landing_page_about_us_section {
        padding: 2rem 1rem;
        height: auto;
    }

    .about_page_break {
        font-size: 1.5rem;
    }

    .landing_page_about_us_section h2 {
        font-size: 1.25rem;
    }

    .contact_input {
        height: 2.5rem;
    }

    .submit_home_contact_form {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
}
@media (max-width: 1200px) {
    .team-grid {
      grid-template-columns: repeat(3, 1fr);  
    }
}

@media (max-width: 768px) {
    .team-grid {
      grid-template-columns: repeat(2, 1fr);  
    }
  }
  
  @media (max-width: 480px) {
    .team-grid {
      grid-template-columns: 1fr;  
    }
  }

  @media (max-width: 480px) {
    .team-card {
      width: 100%;  
      max-width: none;  
      height: auto;  
    }
  
    .display-card h2 {
      font-size: 14px;  
    }
  }



  

  