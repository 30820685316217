
.product_detail_top_grid{
        margin-top: 3rem;
        /* background-color: #fff; */
        border-radius: 10px;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 2rem;
}
.product_detail_top_grid > div {
        grid-column: auto/span 5;
}

.product_detail_top_grid .side_comments{
        grid-column: auto/span 2;
}

.imgMain {
        border: 2px solid rgba(189, 189, 189, 0.199);
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding: 0.9rem;
        overflow: hidden;
        height: 100%;
        max-height: 20rem;
        margin-top: 5rem;
}
.imgMain img {
        width: 100%;
        height: auto;
}
.subImages {
        display: flex;
        margin-top: 0.3rem;
        margin-bottom: 2rem;
}
.imgSub {
        width: 5rem;
        height: 4rem;
        border: 2px solid #a3a3a3;
        margin-right: .5rem;
        overflow: hidden;
}
.imgSub img {
        width: 100%;
}
.product_detail_top_grid .ratings {
        margin-block: 1rem;
        cursor: pointer;
}
.product_detail_top_grid .icon {
        margin-right: .3rem;
        color: var(--main-color);
}
.rating_value {
        margin-left: 1rem;
        margin-top: -0.3rem;
}
.product_detail_top_grid .iconB {
        font-size: .8rem;
        margin-top: 2rem;
        margin-left: 0.4rem;
}
.product_detail_top_grid .priceB{
        font-size: var(--fs-md);
        font-weight: 700;
        color: #000;
}
.price_dis p {
        margin-bottom: .8rem;
}
.shipping_address{
        margin-block: 1rem;
}
.location_country {
        color: rgb(60, 138, 255);
}
.shipping_price p {
        margin-top: 1rem;
}
.shipping_price span{
        color: #000;
        font-weight: 500;
}
.buyBtn {
        outline: none;
        border: none;
        padding: .5rem 2rem;
        color: #fff;
        background: linear-gradient( 30deg, var(--main-color),  #016bce );
        border-radius: 5px;
        font-size: 0.8rem;
        text-transform: capitalize;
        font-weight: 400;
        margin: 1rem;
        margin-right: 1rem;
        margin-left: 0;
}
.product_detail_top_grid .buttons {
        margin-block: 1rem;
}
.addCartbtn {
        background: linear-gradient( 45deg, #016bce, #01c7ce);
}
.wishlistBtn {
        background: #fff;
        border: 1px solid var(--main-color);
        color: var(--main-color);
}
.shipping_talk {
        display: flex;
        align-items: center;
        /* margin-top: 2rem; */
}
.iconB2 {
        font-size: 1.4rem;
        margin-right: 1rem;
}
.shipping_talk p {
        font-weight: 600;
}
.share_product_links .text {
        margin-bottom: 0.7rem;
        font-size: .9rem;
}
.share_product_links , .share_product_links a {
        font-size: 1.6rem;
        margin-top: 2rem;
        color: #a3a3a3;
}

@media(min-width: 50rem){
        .product_detail_top_grid{
                display: grid;
                margin-bottom: 3rem;
        }
        .buyBtn {
                padding: .5rem 2.5rem;
                border-radius: 5px;
                font-size: var(--fs-st);
                text-transform: capitalize;
                font-weight: 600;
                margin-left: 0;
        }
        .imgMain {
                padding: 2rem;
                max-height: 35rem;
        margin-top: 0rem;
        }
        .imgMain img {
                width: auto;
                height: 100%;
        }

}