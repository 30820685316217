.heroSection {
        background-image: radial-gradient(circle at 29% 55%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 4%,transparent 4%, transparent 44%,transparent 44%, transparent 100%),radial-gradient(circle at 85% 89%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 51%,transparent 51%, transparent 52%,transparent 52%, transparent 100%),radial-gradient(circle at 6% 90%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 53%,transparent 53%, transparent 64%,transparent 64%, transparent 100%),radial-gradient(circle at 35% 75%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 6%,transparent 6%, transparent 98%,transparent 98%, transparent 100%),radial-gradient(circle at 56% 75%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 16%,transparent 16%, transparent 23%,transparent 23%, transparent 100%),radial-gradient(circle at 42% 0%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 3%,transparent 3%, transparent 26%,transparent 26%, transparent 100%),radial-gradient(circle at 29% 28%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 51%,transparent 51%, transparent 75%,transparent 75%, transparent 100%),radial-gradient(circle at 77% 21%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 35%,transparent 35%, transparent 55%,transparent 55%, transparent 100%),radial-gradient(circle at 65% 91%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 46%,transparent 46%, transparent 76%,transparent 76%, transparent 100%),linear-gradient(45deg, rgb(0,0,121),rgb(20,20,248));
        color: #fff;
        min-height: 40vh;
        display: flex;
        align-items: center;
        text-align: center;
}
.hero_title {
        margin-bottom: 1rem;
        font-size: 2rem;
        text-align: center;
}
.frequently_asked_question .search {
        background-color: #fff;
}
.search_faq_wrapper_n {
        justify-content: center;
        display: flex;
}
.faq_search_input{
        border-radius: 50px;
        border: none;
}
.search_faq_n {
        width: 90%;
        max-width: 40rem;
        position: relative;
        margin: 1rem 1rem;
        background-color: #fff;
        height: 3rem;
        border-radius: 50px;
}
.search_faq_n input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: var(--fs-st);
        color: #333;
        padding-inline-start: 1rem;
        background: transparent;
        font-weight: 500;
        font-size: calc(0.1rem + var(--fs-md));
}
.search_faq_n input::placeholder {
        color: #999;
}
.search_faq_n .searchIcon {
        position: absolute;
        top: 0;
        cursor: pointer;
        right: 1px;
        height: 100%;
        width: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 49px;
        border-top-right-radius: 49px;
        background-color: #002b8c;
}
.question_head {
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
}
.question_name {
        padding-bottom: 0.8rem;
        padding-left: .3rem;
        font-size: 0.85rem;
        font-weight: 500;
}
.frequent_asked_question_list {
        margin: auto;
        margin-block: 3rem;
        max-width: 40rem;
}
.frequent_question_dropdown_list {
        font-size: 0.8rem;
        padding: 1.5rem;
        padding-top: 0.8rem;
}

.faq_need_help_btn {
        margin-top: 4rem;
        display: flex;
        align-items: center;
}
.faq_btn_n {
        color: #fff;
        background-color: green;
        padding: .5rem 1rem;
        margin-left: 1rem;
}
.faq_btn_n:hover {
        color: #fff;
}
