.create_seller_account {
        background-color: #fff;
        padding-block: 1rem;
        position: relative;
}

.seller_account_head {
        border-bottom: 1px solid var(--gray);
        margin-bottom: 1rem;
        text-align: center;
        padding-bottom: 1rem;
}
.create_seller_account .type {
        text-align: left;
        margin-block: 4rem 2rem;
}
.authFormSellerAccount {
        max-width: 52rem;
}
.create_seller_form textarea {
        width: 100%;
        height: 8rem;
        resize: none;
        padding: .5rem;
        border: none;
        outline: none;
}
.create_seller_form textarea::-webkit-scrollbar {
        display: none;
}
.nationality_wrapper {
        position: relative;
        overflow: visible;
}
.create_seller_form .inputContainer.education {
        max-height: 2.6rem;
}
.create_use_submit {
        margin-block: 2rem;
}
.create_use_submit button {
        background-color: #1280e7;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
        border-radius: 200px;
}

.create_use_submit button:hover {
        background-color: #0768c2;
}
.create_use_submit .loginBtn {
        max-width: 25rem;
        margin: auto;
}
.inputContainer.flex_full {
        max-width: 100% !important;
}
/* ======= country dropdown ========== */
.countrySelectDropDown {
        position: absolute;
        border: 1px solid var(--gray);
        width: 100%;
        border-radius: 4px;
        top: 110%;
        left: 0;
        height: 0px;
        z-index: 5;
        font-size: 0.9em;
        background-color: #fff;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: opacity height 0.3s ease-in-out;
}
.resetImage:disabled {
        background-color: var(--gray);
        cursor: not-allowed;
}
.showCountryDropDown {
        visibility: visible;
        opacity: 1;
        height: auto;

}
.nationality_dropdownlist .countryName{
        padding-left: 0.3rem;
}
.nationality_dropdownlist {
        padding: 0.2rem 0.5rem;
        transition: all .2s ease-in-out;
        cursor: pointer;
}
.nationality_dropdownlist:hover {
        background-color: #0768c21c;
}
.loadMoreContries {
        font-size: 0.9em;
        text-align: center;
        background-color: #0768c21c;
}
.loadMoreContries .flag{
        padding-left: 0.3rem;
        margin-top: 5px;
}

/* ====== SLIDER =========== */

.onboard_slider{
        width: 100%;
        height: 0.3rem;
        position: absolute;
        bottom: 10rem;
        left: 0;
        top: 0%;
        overflow: hidden;
        transition: all 1.5s ease-in-out;
        background-color: orange;
    }
.onboard_slider::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;
        background-color: rgb(6, 17, 119);
        width: var(--after-width, 0);
        transition: all 1.5s ease-in-out;
}


 /* ========= ACTION BUTTONS ============== */
 .next_btn_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
}


.onboard_next_btn {
        color: #fff;
        background-color: var(--main-color);
        border: 2px solid var(--main-color-dark);
        outline: none;
        min-width: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        height: 2.7rem;
        cursor: pointer;
        transition: all .3s ease-in-out;
}
.onboard_next_btn:hover {
        background-color: #93b8d1;
        color: var(--main-color-dark);
}
.onboard_load_btn, .onboard_load_btn:hover {
        color: #fff;
        background-color: #15076677;
        border-color: #1507660a !important;
        cursor: not-allowed;
}
.onboard_next_btn span {
        font-size: 1rem;
        font-weight: 600;
}
.seller_onboard_back_btn {
        background-color: #b3e1ff;
        color: var(--main-color-dark);
}
.btn_svg {
        height: 100%;
        width: 1.4rem;
        margin-left: 1rem;
}
.onboard_next_btn .btn_svg * {
        font-size: 1.5rem;
}
.btn_svg svg {
        height: 100%;
}
    

@media(min-width: 50rem){
        .create_seller_account {
                padding-block: 2rem;
        }
        .seller_account_main_head {
                margin-bottom: 4rem;
        }
        .create_seller_form {
                margin-top: 2rem;
                display: flex;
                justify-content: space-between;
        }
        .authFormSellerAccount {
                width: 63%;
        }
        .create_account_side {
                width: 33%;
                border: 1px solid var(--gray);
                border-radius: 6px;
                max-height: 40rem;
                overflow: hidden;
                color: #7c7c7c;
                position: sticky;
                top: 20%;
                max-height: 30rem;
        }

        .create_seller_form .name_input_flex{
                display: flex;
                justify-content: space-between;
        }
        .name_input_flex{
                justify-content: space-between;
                display: flex;
        }
        .create_seller_form .inputContainer {
                max-width: 25rem;
                margin-right: .5rem;
        }
        
        .create_seller_player {
                width: 100%;
                height: 10rem;
                background: linear-gradient( to right, #12e7d5, #1280e7 );
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
        }
        .create_seller_aside .title {
                margin-bottom: 1rem;
        }
        .create_seller_aside {
                width: 90%;
                margin: 2rem auto;

        }
        .create_seller_form .profile_pic_input {
                width: 100% !important;
        }


        /* .onboard_slider{
                top: auto;
                position: absolute !important;
                bottom: 7rem !important;
        }
        .onboard_slider::after {
                background-color: #0354a549;
        } */
}