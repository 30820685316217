.landingPage {
        padding-block: 2rem;
}

.app_heroSection {
        min-height: 70vh;
        width: 100%;
        display: flex;
        align-items: center;
        /* overflow: hidden; */
}
.animate_ball{
        margin-top: 20px;
}
.app_heroSection .content {
        width: 70%;
}
.hero_img_wrapper {
        width: 25%;
        padding-top: 6.5rem;
        position: relative;
        padding-left: 1rem;
}
.landingPage h2 {
        font-size: var(--fs-lg);
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: 1.5rem;
}
.app_heroSection .hero-main-title {
        font-size: 1.5em;
}

.app_heroSection h2 span {
        font-style: italic;
        font-weight: 500;
}
.landingPage .landing_desc {
        font-size: 1.2em;
        margin-bottom: 2rem;
}


.submitBtn {
        height: 2.3rem;
}
.landingPage .submitBtn {
        display: inline;
        padding: 0.7rem 1rem;
        font-size: 0.8rem;
}
/* ============ ANIMATE BALL ============== */
.animate_ball {
        width: 7rem;
        height: 7rem;
        background-color: #42a4ff46;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 25px;
        /* display: none; */
}


.animate_ball img {
        height: 100%;
        pointer-events: none;
}
.ball_two {
        width: 5rem;
        height: 5rem;
        margin-top: 0;
        float: right;
}
.ball_three {
        width: 4rem;
        height: 4rem;
        float: right;
}
.landing_section_flex .landing_img_wrapper {
        width: 100%;
        overflow: hidden;
}
.landing_img_wrapper img {
        width: 100%;
        border-radius: 9px;
}

.popular_sellers {
        padding-top: 2rem;
}
.popular_sellers h2 {
        text-align: left;
}
/* =========== POPULAR CATEGORIES ================= */
.landing_book_category {
        margin-bottom: 3rem;
}
.landing_title_center {
        text-align: center;
}
.landing_category_scroll_wrapper {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 21.8rem;
}
.landing_category_scroll {
        width: 1000px;
}

/* =========== BANNER UNDER ================ */
.landing_banner {
        height: auto;
        margin-top: 1rem;
        min-height: 10rem;
        background: linear-gradient(to right, var(--main-green), rgb(91, 255, 186));
        color: #fff;
        padding: 1rem 2rem;
}
.landing_banner_img {
        display: none;
        background-size: cover;
        margin-right: 50px;
        background-position: center;
}

@media(max-width: 50rem) {
        .app_heroSection {
                min-height: 80vh;
                width: 100vw;
                margin-left: calc((100% - 100vw) / 2);
                display: flex;
                align-items: center;
                overflow: hidden;
                padding-left: 1rem;
        }
        
}
@media(min-width: 50rem){
        .app_heroSection .content {
                width: 100%;
                max-width: 700px;
                font-weight: 400;
        }
        .landingPage h2 {
                font-size: 1.4rem;
                font-weight: 700;
        }
        .app_heroSection .hero-main-title {
                font-size: 3em;
        }       
        .landingPage .landing_desc {
                font-size: 1.4em;
                font-weight: 400;
        }
        .app_heroSection .landing_desc {
                margin-bottom: 4rem;
        }
        .landingPage .submitBtn {
                display: inline;
                padding: 0.5rem 1.5rem;
                font-size: var(--fs-st);
        }
        .landingPage .submitBtn:hover{
                color: #fff;
        }
        .hero_img_wrapper {
                /* width: 45%; */
        }
        .animate_ball {
                width: 10rem;
                height: 10rem;
        }
        .ball_two {
                width: 7rem;
                height: 7rem;
                margin-left: 100%;
        }
        .ball_three {
                width: 5rem;
                height: 5rem;
                position: absolute;
                top: 0;
                right: 0;
        }
        .landing_section_flex {
                display: flex;
                align-items: center;
                justify-content: space-around;
        }
        .landing_section_flex .content {
                width: 50%;
        }
        .landing_section_flex .landing_img_wrapper {
                width: 40%;
                height: 100%;
        }

        /* ========== CATEGORIES -=========== */
        .landing_category_scroll_wrapper {
                overflow: hidden;
        }
        .landing_category_scroll {
                width: 100%;
        }

        /* ========== BANNER ================ */
        .landing_banner {
                display: flex;
                justify-content: space-between;
                align-items: center;
        }
        .landing_banner_img {
                display: block;
                max-height: 15rem;
                overflow: hidden;
                width: 40%;
        }
        .landing_banner_img img {
                height: 15rem;
                margin-right: 90px;
        }

}


.not_found_n {
        min-height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #777;
}
.notfound_title_n {
        text-align: center;
        font-weight: 700;
        font-size: 1.3rem;
}
.notfound_text_head_n {
        margin-bottom: 2rem;
}
.notfound_svg_n {
        width: 90%;
        max-width: 500px;
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
}
.notfound_desc_n {
        text-align: center;
        margin-top: 1rem;
}
.notfound_login_n .submitBtn{
    padding: .5rem 4rem;
    background-color: #0056d6;
    color: #fff;
    border-radius: 30px;
    margin: 2rem auto;
    cursor: pointer;
    display: block;
    width: 100%;
    text-transform: capitalize;
}

.app_heroSection .submitBtn {
        font-size: var(--fs-lg);
}
.submitBtn_login {
        margin-left: 2rem !important;
        border: 1px solid var(--main-color) !important;
        background-color: #fff !important;
        color: var(--main-color) !important;
}

.pagination {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin-block: 2rem;
}
.pagination_count {
        color: #000080;
        border: 1px solid #000080;
        border-radius: 15px;
        padding: .2rem .55rem;
        margin-right: 1rem;
        cursor: pointer;
}

.active_btn{
        background-color: #000080;
        color: #fff;
        border: 1px solid #000080;
}


.landing_section_flex {
        min-height: 40vh;
        /* min-height: 50rem; */
        margin-bottom: 2rem;
}
.landing_section_flex .content, 
.landing_section_flex .landing_img_wrapper{
        margin-top: 1rem;
        opacity: 0;
        transform: translateY(80px);
}

.animateOne {
        animation: fadeIn 0.8s forwards ease-in-out ;
}
@keyframes fadeIn{
  from{
        opacity: 0;
        transform: translateY(80px);
  }
  to{
        opacity: 1;
        transform: translateY(0%);
  }
}
@media(max-width: 50rem){
        .bring_know_section {
                display: flex;
                flex-wrap: wrap;
        }
        .bring_know_section .content{
                order: -1;
                width: 100%;
        }
        .submitBtn.mobile_call_to_action {
                display: block !important;
                max-width: 9rem;
                text-overflow: clip;
                overflow: hidden;
                margin: 0px;
                margin-bottom: 0.8rem;
                line-height: 2rem;
        }
        .submitBtn.mobile_call_to_action_2:hover{
            color: white;
                font-weight: 400;
        }
        .submitBtn_login.mobile_call_to_action_sec {
                margin: auto;
                display: block;
                max-width: 9rem;
                text-align: center;
                margin-left: 4rem !important;
                margin-top: 1.5rem;
                padding-bottom: 2rem;
        }
        
}