.panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.instructions {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    position: relative;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.instructions .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
}

h2 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
}
.account_details_title{
    font-size: 1.1rem;
    text-align: left;
    font-weight: 400;
}
.account_details_title a{
    text-decoration: none;
    font-weight: 600;

    &:hover{
        text-decoration: underline;
    }
}
.account_details_title span{
    font-weight: 700;
    color: #002b8c;
}

.balance {
    color: #1a1a1a;
    font-weight: bold;
}

.form-group {
    margin-bottom: 1.5rem;
    text-align: left;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    outline: none;
}

.password-input {
    position: relative;
}

.toggle-password {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.error {
    color: red;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.modal-submit-btn {
    width: 100%;
    padding: 0.8rem;
    background: #000080;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-submit-btn:hover {
    background: #011b33;
    transition: 0.2s ease;
}

.submit-btn:disabled {
    background: #6c757d;
    cursor: not-allowed;
}

.loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #007bff;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
