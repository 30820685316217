.main_transaction{
    margin: 0 auto;
    background-color: #f5f5f5;
}

.transaction_container{
    background-color: inherit;
    max-width: 1200px;
    padding: 2rem;
    margin: 0 auto;
    height: auto;
}

.transaction_title{
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    color: #000080;
    margin-block: 1.5rem;
}
.transaction_title button{
    font-weight: 400;
    background-color: #007e2c;
    font-size: 0.9rem;
    font-family: 'Open Sans', sans-serif;
}
.transaction_title button:hover{
    background-color: #006207;
    transition: 0.2s ease;
    color: #fff;
}

.transaction_grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}

.grid_item{
    max-height: 250px;
    display: flex;
    flex-direction: column;
}

.grid_item_header{
    color: #1e1e1e;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    margin-block: 1rem;
}
.grid_item_card{
    display: flex;
    flex-direction: column;
    padding: 0.7rem;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #006750;
}
.transaction_grid .grid_item:nth-child(2) .grid_item_card {
    background-color: #d55b00;
}

.transaction_grid .grid_item:last-child .grid_item_card {
    background-color: #b63f3f;
}

.card_item_title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.card_item_title h2{
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
}

.item-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 50px;
    height: 50px;
}

.item-icon svg{
    width: 25px;
    height: 25px;
}

.card_details{
    margin-block: 1.5rem;
    color: #fff;
    display: block;
}

.card_details span{
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
}
.card_details p{
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    align-items: center;
}
.card_details p a{
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-left: 12px;
}

.card_details p a svg{
    margin-left: 5px;
}

.card_details p a:hover{
   text-decoration: underline;
   color: white;
   transition: 0.2s ease;
}
.panel{
    display: flex;
}

.status {
    font-weight: bold;
    text-transform: capitalize;
}

.status.approved {
    color: green;
}

.status.pending {
    color: orange;
}

.status.failed {
    color: red;
}

.status.refunded {
    color: violet;
}

.row_item_stat{
    font-size: 1rem;
    text-align: left;
    font-weight: 400;
    color: #3b2f6c;
}
.rdt_TableHeader {
    font-size: 16px;
    font-weight: bold;
}

.rdt_TableRow {
    font-size: 14px;
    font-weight: normal;
}
/* hover on line */
.rdt_TableRow:hover {
    background-color: #eaf4ff;
    transition: background-color 0.2s;
}
.responsive-table-wrapper {
    overflow-x: auto;
    width: 100%;
}

.rdt_TableCell {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    white-space: normal;
}

/* Pagination personnalisée */
.rdt_Pagination button {
    background-color: #002b8c;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 14px;
}

/* Ensure icons inside buttons are styled */
.rdt_Pagination button svg,
.rdt_Pagination button i {
    fill: white; /* For SVG icons */
    color: white; /* For font-based icons like Font Awesome */
}

.rdt_Pagination button:hover {
    background-color: #0056b3;
}

.rdt_Pagination button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

/* Pagination container */
.rdt_Pagination {
    background-color: #f9fafb;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    gap: 20px;
}
.rdt_TableHeadRow .rdt_TableCol {
    font-weight: bold !important;
    font-size: 16px !important;
}

.rdt_Pagination select {
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 2px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC41IDYgOCAxMC41IDEzLjUgNiIgZmlsbD0iIzAwMCIvPgo8L3N2Zz4=') no-repeat right 10px center;
    background-color: white;
    background-size: 15px;
    background-clip: padding-box;
    pointer-events: auto;
    position: relative;
    z-index: 1;
    width: 200%
}

/* Remove duplicate or extra dropdown arrows */
.rdt_Pagination select + select {
    display: none; /* Hides unintended extra dropdown */
}

/* Media queries  */
@media (max-width: 600px){
    .row_item_header_stat {
        font-size: 25px;
    }
    .rdt_TableCell {
        padding: 4px;
    }
    .rdt_Pagination{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding-inline: 0;
    }
    .rdt_Pagination select{
        /* padding-right: 10px; */
        padding: 4px;
        margin: 0 ;
        display: flex;
        justify-content: space-between;
    }
    .rdt_Pagination button {
        font-size: 12px;
        padding: 4px 8px;
        margin: 5px 0;
    }
}

@media (max-width: 480px) {
    .row_item{
        font-size: .8rem;
        padding: 2px;
        margin: 0;
    }
    .rdt_Pagination button {
        font-size: 10px;
        padding: 3px  6px;
        margin: 2px -10px;
        width: auto;
        height: auto;
    }
    .rdt_Paginationn select{
        width: auto;
        height: auto;
        padding: 2px;
    }
}
