.seller_create_book {
        padding-block: 2rem;
        margin-top: -2rem;
}
.flex_create_book {
        display: flex;
        padding-inline: 1rem 0.3rem;
}

.create_book_tooltip {
        display: none;
}
.book_create_status {
        background-color: #43810B;
        width: 100%;
        font-size: var(--fs-lg);
        text-align: center;
        padding: .4rem;
        color: #fff;
        border-radius: 4px;
        margin-bottom: 1rem;
        cursor: pointer;
}
.publish_status_not_active {
        background-color: #42810b4d;
        cursor: not-allowed;
}
.preview_status {
        background-color: #99999962;
        border: 1px solid var(--gray);
}
.book_manager_title {
        border-bottom: 1px solid var(--gray);
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;
}
.status_live {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
}
.status_live p {
        font-size: var(--fs-st);
}
.book_title {
        margin-bottom: 2rem;
        font-weight: 400;
        font-size: 1.5rem;
        margin-left: 0;
        font-family: 'Open Sans', sans-serif;
}
.word-wrap{
        text-wrap: wrap;
        width: auto;
        margin: 0;
        padding: .1rem auto;
        text-align: center;
        color: crimson;
        font-size: var(--fs-st);
}
.create_book_form .seller_profile_wrapper{
        display: block;
}
.profile {
        display: block !important;
        margin-bottom: 1.5rem;
        position: relative;
}
.profile_picture {
        display: flex;
        justify-content: center;
        overflow: hidden;
}
.profile_picture .profile {
        /* width: 80%;
        max-width: 200px;
        margin: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
}
.seller_profile_wrapper.create_book_media {
        border: 1px dashed var(--gray);
        border-radius: 5px;
        height: 10rem;
        align-items: center;
}
.isCoverMedia{
        border-color: #43810B;
}
.seller_profile_wrapper.create_book_media .profile{
        height: 100%;
        
}
.seller_profile_wrapper.create_book_media input {
        height: 10rem;
        width: 100%;
        outline: none;
        border: none;
}

.profile_picture .image_picker {
        border: 1px dashed var(--gray);
        background-color: #f5f5ff;
        border-radius: 50%;
        height: 10rem;
        width: 10rem;
        position: relative;
        overflow: hidden;
}

.profile_picture .image_picker.coverPhotoAvailable {
        min-height: 45rem;
        border: none;
        background-color: #f5f5ff;
}
.profile_picture input {
        height: 100%;
        border: none;
        cursor: pointer;
}

.profile_picture .seller_title {
        text-align: center;
        color: #888;
        width: 100%;
}

.profile_picture .resetImage_pp {
        position: absolute;
        top: 10px;
        right: -70px;
        background-color: blue;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
}
.profile_picture .resetImage_pp:disabled {
        background-color: var(--gray);
        cursor: not-allowed;
}
.profile .imageCrop {
        height: 12.6rem;
        border-radius: 5px;
}
.profile .imageCrop img{
        height: 12.6rem;
}

.profile h4 {
        margin-bottom: .5rem;
}
.create_book_form .input_seller_text {
        padding-block: 0.7rem;
}
.imagePicker_decoy {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.5rem;
        color: rgba(107, 107, 107, 0.226);
        overflow: hidden;
}
.seller_title {
        margin-right: 0;
        font-size: 14px;
}

.custom-category-dropdown{
        background-color: ghostwhite;
}
.validateStrings {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: var(--fs-st);
}
.validateStrings .invalid {
        color: crimson;
}
.validateStrings .validated_instruction_text{
        color: green;
}
.more_custom_select{
        border: 1px solid rgb(21, 211, 21);
        border-radius: 4px;
}
.book_text_area {
        width: 100%;
        outline: none;
        border: 1px solid var(--gray);
        border-radius: 4px;
        padding: .7rem;
        min-height: 7rem;
        resize: none;
}
.input_validated{
        border-color: rgb(21, 211, 21)
}
.book_forminput_textarea {
        position: relative;
        margin-top: 3rem;
}
.float_input {
        position: absolute;
        top: 50%;
        right: 0;
        font-size: var(--fs-st);
        transform: translateY(-10%);
        margin-right: 2rem;
        opacity: 0.7;
}
.book_description_count {
        font-size: 0.7rem;
        color: crimson;
        position: absolute;
        bottom: 0;
        right: 0;
        /* transform: translateY(120%); */
}
.book_text_area::-webkit-scrollbar {
        display: none;
}
.seller_create_book .create_book_form .price_code_flex {
        display: flex;
}
.promocode input {
        text-transform: uppercase;
}

/* ===================== TAGS ============/ */
.profile_tags {
        display: flex;
        flex-wrap: wrap;
}
.profile_tags .tag {
        width: auto;
        background-color: rgba(0, 140, 255, 0.151);
        border-radius: 4px;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline-start: 1rem;
        overflow: hidden;
        text-transform: capitalize;
        margin: 0.4rem;
        font-size: calc(var(--fs-st) - 0.1rem);
}
.tag_remove_btn {
        width: 2rem;
        height: 100%;
        background-color: rgba(0, 0, 255, 0.096);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
}

/* ======================= BOOK CREATE TIP ============= */
.input_detail_for_input {
        width: 100%;
        /*max-width: 18rem;*/
        /* margin-right: auto; */
        position: relative;
}
.aside_tooltip_wrapper {
        border: 1px solid var(--gray);
        border-radius: 8px;
        overflow: hidden;
}
.input_detail_for_input .detail_head {
        width: 100%;
        height: 2.4rem;
        background-color: var(--main-color);
        color: #fff;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
}
.tooltip_content {
        padding: 1rem;
        font-size: 0.85rem;
        line-height: 2rem;
}
.create_book_form .create_book_button {
        width: 2rem;
        padding: 0;
        padding-block: 0.3rem;
}
.create_book_button > span {
        font-size: 0.9rem !important;
        font-family: Quicksand;
}
.create_book_button .book_submit_btn_loader {
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
}
.book_submit_btn_loader svg {
        height: 1.3rem !important;
}
.tooltip_aside {
        margin-top: 2rem;
}

.remove_cover_photo_btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 30;
        background-color: blue;
        color: #fff;
        padding: 0.2rem 0.6rem;
        font-weight: 500;
        cursor: pointer;
}

/* =================================== */
.saving_book_loader {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000080b4;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 500;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all .5s ease-in-out;
}
.saving_book_loader >div {
        display: flex;
        align-items: center;
        flex-direction: column;
}
.saving_book_loader p {
        margin-top: 1rem;
}
.saving_book_loader.show_book_saving_loader {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
}


@media(min-width: 50rem){
        .flex_create_book {
                justify-content: space-between;
        }
        .create_book_form {
                width: 65%;
                margin-top: 2rem;
                position: relative;
        }
        .create_book_tooltip {
                display: block;
                width: 28%;
                position: sticky !important;
                top: 0;
        }
        .create_book_tooltip > div {
                position: fixed;
                top: 18%;
                right: 5%;
                width: inherit;
                margin: inherit;
                transition: .8s ease-in-out;
        }
        .create_book_tooltip > div.no-sticky{
                position: relative;
                top: auto;
                transition: 0.4s ease-in-out;
        }
        .create_book_tooltip > div.setTopSticky{
                top: 1%;
                transition: .8s ease-in-out;
        }
        .create_bookWrapper {
                border: 1px solid var(--gray);
                padding: 2rem;

        }
        .mobile {
                display: none;
        }
        .profile_tags .tag {
                height: 2.3rem;
                margin: 0.6rem;
                font-size: calc(var(--fs-st) - 0rem);
        }
        .imagePicker_decoy {
                font-size: 4rem;
                color: rgba(80, 80, 80, 0.13);
        }
}
