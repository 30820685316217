:root {
  --main-color: #002b8c;
  --light-accent-color: rgba(0, 0, 255, 0.096);
  --main-green: rgb(34, 155, 89);
  --gray: #d6d6d6;
  --dark-main: #001E3C;
  --golden-yellow: #F7B32F;
  --dark-secondary: #0A1929;
  --main-padding: 1rem;
  --fs-md: 0.8rem;
  --fs-lg: 1rem;
  --fs-st: 0.775rem;
  --fs-sm: 0.7rem;
  --fs-xs: 0.65rem;
}
#root .App {
  margin-block: .3rem;
  min-height: 100vh;
}
.container {
  width: 93%;
  margin: auto;
  max-width: 82.5rem;
}
input{
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  font-family: 'Open Sans', sans-serif;

}
a {
    color: var(--dark-color);
    text-decoration: none;
    background-color: transparent;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}

.shadow{
  width: 100%;
  height: 100%;
  background-color: #0000003b;
}
.title{
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}
ul{
  list-style-type: none;
}
.bold {
    font-weight: bold;
}
@media(min-width: 60rem){
    #root .App {
      margin-block: 0rem;
      overflow: hidden;
  }
  a:hover {
      color: var(--main-color);
  }
}
@media(max-width: 60rem){
    #root .discover_top_pin {
      display: none;
  }
}



/* ===========================PIN DISCOUNT =============================== */

.discover_top_pin {
  height: 5rem;
  background: linear-gradient(to right, #E6F7E3, #81D8B8);
  display: flex;
}

.discover_top_pin .discover_img  {
  height: 100%;
  width: 100%;
}

