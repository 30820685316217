.landing_explore {
    background: linear-gradient(to right, rgba(0, 0, 16, 0.7), rgba(0, 0, 48, 0.6)), url("../../../../Assets/img/bg-books2.jpg") no-repeat center center;
    background-size: cover;
    color: #fff;
    min-height: 24rem;
    align-items: center;
    display: flex;
}
.landing_explore p{
   white-space: break-spaces;
    font-weight: 300;
}
.landing_explore h1{
      font-weight: 400;
      padding: 0;
}
.explore_main_flex {
    display: flex;
    /* flex-wrap: wrap; */
    color: var(--main-color);
    align-items: center;
}

.explore_main_flex > div {
    width: 100%;
}

.content_wrapper {
    width: 60%;
    max-width: 50rem;
    margin: auto;
}
.content_wrapper.right_card {
    margin: auto;
}
.content_wrapper * {
    width: 95%;
}
.content_wrapper p {
    color: #000;
    padding: 14px 0 14px 0;
}
.content_wrapper h1 {
     font-weight: 400;
}
.explore_main_img {
    height: 30rem;
    position: relative;
    width: 100%;
    overflow: hidden;
}
.explore_main_img_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150%;
    transition: all .3s ease;
}
.explore_main_img {
    background-color: var(--main-color);
}
.explore_main_flex:hover .explore_main_img_img {
    height: 180%;
}
.learn_more {
    display: flex;
    align-items: center;
    background-color: black;
    border-radius: 0px;
    max-width: 8rem;
    border: 1px solid #000;
    transition: all .3s ease;
}
.learn_more:hover {
    color: #000;
    background-color: #fff;
}


@media(max-width:50rem){
    .explore_main_img {
        display: none;
    }
    .learn_more {
        width: 100%;
    }
    .content_wrapper.right_card, .content_wrapper {
        all: unset;
    }
    .explore_main_flex {
        max-width: 80%;
        margin: 1rem auto;
    }
}