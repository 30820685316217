.privacy_policy li {
    margin-bottom: 1rem;
}
.privacy_policy ol {
    margin-left: 1rem;
}
.privacy_policy h2 {
    text-align: justify;
}
.refund_policy, .privacy_policy {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background-color: #f4f4f4;
}
.privacy_policy h1, .refund_policy h1{
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: left;
    font-size: 1.8rem;
    font-weight: 500;
}
.privacy_policy h2, .refund_policy h2 {
    margin-top: 1.5rem;
    margin-bottom: .9rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
}

.date_update {
    margin-bottom: 1rem;
}
.refund_policy .laws{
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.privacy_policy li, .refund_policy li{
    list-style-type: disc;
    margin-left: 1rem;
    line-height: 1.5rem;
}
.privacy_policy a, .refund_policy a{
    text-wrap: nowrap;
}
.summary_intro{
    margin-bottom: 1rem;
}
@media(min-width: 50rem){
    .privacy_policy p, .refund_policy p {
        line-height: 1.8rem;
    }
    .privacy_policy h2, .refund_policy h2{
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }
}

