.pagination-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.pagination-button{
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    background-color: #000080;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}
.page-number {
    border: 1px solid #000080;
    border-radius: 15px;
    color: #000080;
    background-color: #f9f9f9;
    cursor: pointer;
    font-weight: bold;
    padding: .2rem .55rem;
}

.pagination-button.disabled-page {
    cursor: not-allowed;
    background-color: #0000807c;
    margin-inline: 0.3rem
}

.active-page {
    border-radius: 15px;
    padding: .2rem .55rem;
    margin-right: 1rem;
    background-color: #000080;
    color: #fff;
    border: 1px solid #000080;
    cursor: pointer;
    font-weight: bold;
}

