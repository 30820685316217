

.book_details {
    width: 95%;
    padding: 0rem;
}
.book_card {
    border: 1px solid var(--gray);
    padding: 1rem;
    border-radius: 8px;
    margin-top: 2rem;
    cursor: pointer;
}
.book_card_img_wrapper {
    width: 80%;
    aspect-ratio: 3/4;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
}
.book_card_img_wrapper img {
    height: 100%;
}
.book_card .book_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem;
    font-size: var(--fs-md);
}
.book_card .key {
    font-weight: bold;
}
.book_details_description {
    padding-bottom: 4rem;
}
.book_details_description .desc_text {
    margin-bottom: 1.5rem;
    font-size: .9rem;
    font-weight: 400;
    text-align: justify;
}

.ebook_specific_share_urls {
    padding: 2rem 0;
    
}
.ebook_specific_share_urls * {
    font-size: var(--fs-md);
}
.ebook_specific_share_urls li {
    display: flex;
    padding: 0.3rem 1rem;
    cursor: pointer;
}
.ebook_specific_share_urls .icon *{
    font-size: 1.5rem;
}
.ebook_specific_share_urls .icon {
    margin-right: 1rem;
}
.ebook_specific_share_urls .fb {
    color: #0863F7;
}
.ebook_specific_share_urls .linIn {
    color: #007AB5;
}
.ebook_specific_share_urls .copy_store {
    background-color: #b8b8ff;
    max-width: 250px;
    align-items: center;
    border-radius: 4px;
}
.book_details .delete_btn {
    background-color: crimson;
}
.card_border{
    border: 1px solid var(--gray);
    padding: 1rem;
    border-radius: 8px;
    margin-top: 2rem;
    cursor: pointer;
}
@media(min-width: 60rem) {
    .book_card_head_flex {
        display: flex;
        gap: 20px;
        width: 100%;
        padding-bottom: 2rem;
    }
    .book_card_head_flex .book_card.left {
        width: 35%;
        margin-right: 2rem;
        background-color: #e2e2fc;
    }
    .book_card_head_flex .book_detail_right {
        width: 70%;
        /* display: flex;
        flex-direction: column;
        gap: 25px; */
    }
    .book_card_head_flex .book_detail_right .book_text {
        justify-content: flex-start;
    }
    .book_card_head_flex .book_detail_right .book_text .key {
        min-width: 100px;
    }
    .book_card_head_flex .book_detail_right .book_card {
        width: 100%;
    }
    .book_card_desc_wrapper {
        height: auto;
    }
}