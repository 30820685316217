@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
.authenticate * {
    font-family: 'Montserrat', sans-serif;
}
.auth_n {
    background: #fff;
    height: auto;
    min-height: 100vh;
    padding-top: 5rem;
}
.authenticate{
    position: relative;
}
.authenticate textarea{
    all: unset;
}


.authenticate textarea::-webkit-scrollbar{
    background-color: transparent;
    width: 1rem;
}

.authenticate__image_wrapper{
    overflow: hidden;  
    height: 100%;
    display: none;
}
.authenticate__content{
    background-color: #fff;
    width: 95%;
    margin: auto;
}

.authenticate button, .authenticate button:focus{
    outline: none;
    border: none;
}
.authenticate__content_container{
    max-width: 34rem;
}
.forgot_password_story {
    margin-bottom: 0rem;
}
.google_btn_child > * {
    pointer-events: none;
}
.authenticate .alert{
    width: 100%;
    background-color: rgba(220, 20, 60, 0.479);
    border: 1px solid crimson;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: .7rem;
    transition: all .3s ease-in-out;
    display: none;
}

.authenticate .showAlert{
    display: block;
}
.authenticate .showAlert.phoneMessage{
    background-color: #6db67479;
    border: 1px solid #6DB674;
}

.authenticate__content p{
    text-align: center;
    margin-bottom: 1rem;
}
.authenticate__content .contact_desc{
    text-align: left;
    margin-bottom: 1.3rem;
}

.authenticate__content h3{
    font-size: var(--fs-h3);
    text-transform: capitalize;
    font-weight: 500;
    text-align: center;
}


.authenticate__content_container .inputField{
    position: relative;
    margin-bottom: 0.3rem;
    height: 4rem;
    width: 93%;
    margin: auto;
    overflow: hidden;
}
.authenticate__content_container .message{
    height: 6.5rem;
    margin-top: 2rem;
}

.authenticate__content_container .inputField input,
.authenticate__content_container .inputField textarea {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    /* padding-inline-start: .7rem; */
    padding-top: 1.7rem;
    font-size: 1rem;
    background: transparent;
}
.authenticate__content .label{
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: #adadad;
    font-size: 0.9rem;
    transition: all .3s ease-in-out;
    border-bottom: 1px solid #232e4245;
}

.inputField .label::after{
    content: '';
    position: absolute;
    height: 100%;
    left: 0px;
    bottom: -1px;
    width: 102%;
    border-bottom:2px solid #6f6b6ac9;
    transform: translate(-110%, 0%);
    transition: all .3s ease-in-out;
}
.label .labelContent{
    position: absolute;
    bottom: 10%;
    transition: all .3s ease-in-out;
}


.inputField input:focus ~.label .labelContent,
.inputField input:valid ~.label .labelContent{
    transform: translate(0%, -120%);
    font-size: .8rem;
}

.inputField input:focus ~.label::after,
.inputField input:valid ~.label::after{
    transform: translate(0%, 0%);
}
.inputField textarea:focus ~.label .labelContent,
.inputField textarea:valid ~.label .labelContent{
    transform: translate(0%, -120%);
    font-size: .8rem;
}

.inputField textarea:focus ~.label::after,
.inputField textarea:valid ~.label::after{
    transform: translate(0%, 0%);
}
.auth_input_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.auth_input_flex .inputField {
    width: 60%;
}
.auth_input_flex .inputField.country_list_auth {
    width: 100%;
}
.auth_country_wrapper {
    margin: auto;
    width: 30%;
    margin-right: 0px;
    position: relative;
}
.country_list_suggestions {
    position: absolute;
    top: 110%;
    width: 150%;
    height: auto;
    border-radius: 4px;
    border: 1px solid #ddd;
    z-index: 2;
    background-color: #f5f5ff;
    max-height: 400px;
    overflow-y: scroll;
}

.country_list_suggestions .country_list_item {
    padding: 0.6rem 1.5rem;
    display: flex;
    transition: all .3s ease-in-out;
    cursor: pointer;
    font-size: 0.8rem;
}
.country_list_suggestions .country_list_item:hover {
    background-color: #0000804d;
}
.preview_country_icon {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    height: 80%;
    display: flex;
    align-items: center;
}
.country_list_suggestions .icon {
    height: 20px;
    margin-right: 1rem;
}
.preview_country_icon img,
.country_list_suggestions .icon img {
    width: 30px !important;
}

.country_list_suggestions::-webkit-scrollbar {
    display: none;
}

.authenticate__content_container .message textarea:focus ~.label .labelContent,
.authenticate__content_container .message textarea:valid ~.label .labelContent{
    transform: translate(0%, -380%);
    font-size: .8rem;
}
.authenticate .authenticate__content_container textarea{
    resize: none;
    max-height: 5rem;
    padding-top: 1rem;
    width: 100%;
}


.authenticate .mobile-image{
    width: 100vw;
    margin-block: .3rem;
    /*transform: translateX(-7%);*/
}

.authenticate__content .forgotPassword{
    margin: .5rem 0rem;
    text-transform: capitalize;
    color: #cf0057;
    text-align: right;
}
.authenticate__content .buttonWrapper{
    display: flex;
    margin: auto;
    position: relative;
}
.authenticate__content .buttonWrapper.signup {
    display: block;
}
.authenticate__content  .forgotPassword:hover{
    text-decoration: underline;
}
.submitBtn{
    padding: .1rem 5rem;
    background-color: #0056d6;
    color: #fff;
    border-radius: 30px;
    font-size: 0.9rem;
    margin: 2rem auto;
    font-weight: 400;
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
}
.submitBtn.register {
    margin-bottom: 1rem;
}
.submitBtn.changeMethod {
    margin-left: 1rem;
    padding-inline: 1.2rem;
    background-color: #00008033;
    color: #000;
    margin-top: 0rem;
}
.submitBtn.changeMethod span {
    margin-left: 0.5rem;
}
.submitBtn.changeMethod span *{
    font-size: 1.1rem;
}
.loadingButton {
    overflow: hidden;
    display: block;
    width: 45px;
    margin-left: 1rem;
}
.buttonText {
    display: block;
}
.loadingButton svg {
    width: 100%;    
    height: 100%;
}
.authenticate p{
    margin-bottom: 3rem;
}
.forgot_password_n .authenticate p{
    margin-bottom: 2rem;
}
.back_to_login {
    color: #1a7cfd;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
}

.signupBtn{
    border-top: 2px solid #00000034;
    max-width: 25rem;
    margin-top: 3rem;
}

.or{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 1rem;
}
.other_autheticate{
    justify-content: center;
    display: flex;
    margin: auto;
    padding-top: 3rem;
}
.authenticate .link{
    font-weight: bold;
    text-transform: capitalize;
}

.other_autheticate >div{
    display: block;
    height: 3rem;
    width: 3rem;
    margin-right: 1.5rem;
}
.other_autheticate img{
    height: 100%;
}

.contactPage .buttonWrapper{
    max-width: 25.25rem;
}

/* email verification */
.email-verification {
    background-color: #eee;
    height: 100vh;
}

.circle {
    color: var(--clr-accent);
    width: 4rem;
    height: 4rem;
    margin: 1rem;
}
.rs_heading {
    margin-block: 2rem;
    text-align: center;
    font-weight: 600;
}
.rs_svg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25rem;
    margin-bottom: 3rem;
}
.rs_svg svg {
    height: 100%;
}
.rs_notice {
    max-width: 600px;
    background-color: #CDF6DA;
    color: #333;
    border-radius: 8px;
    padding: 1rem;
    margin: auto;
    margin-bottom: 2rem;
}
.rs_notice p {
    margin-top: 1rem;
}

.email-text {
    text-align: center;
    margin-bottom: 2rem;
}

.email-container {
    width: 40rem;
    height: 40rem;
    display: flex;
    margin: auto;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-light);
}

.email-heading {
    font-weight: 500;
    font-size: larger;
}
.forgot_password_link_n {
    cursor: pointer;
    border-bottom: 1px solid black;
}

.svg_login {
    height: 25rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

}
.svg_login svg{
    width: 70%;
}
.signup_page_specific {
    height: 18rem;
}
.forgotpassword_page_specific {
    height: 12rem;
    justify-content: flex-start;
}
.forgotpassword_page_specific svg {
    width: 40%;
}
/* ============== OAUTH BUTTON ================= */


.oauthBtn {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
}
.facebookImg {
    width: 100% !important;
}
.googleBtn {
    position: relative;
    overflow: hidden;
}
.google_placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    pointer-events: none;
    /* border: 1px solid rgb(206, 206, 206); */
}
.authBtn {
    width: auto ;
    margin-bottom: 3rem;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
}

/* ============ EMAIL VERIFY ================== */
.email_verify {
    background-color: #fff;
    height: 30rem;
}
.email_verify_loader {
    max-width: 40rem;
    width: 80%;
    height: 10rem;
    margin: 2rem auto;
}
.verify_email_text {
    background-color: #CDF6DA;
    padding: 1rem;
    border-radius: 8px;
    color: #333;
    text-align: center;

}
.continue_browsing {
    margin-top: 2rem;
    text-align: center;
}

.continue_browsing a {
    color: #1a7cfd;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* Space between the icon and text */
}
.continue_browsing a{
    color: #1a7cfd;
}
.continue_browsing a svg {
    margin-right: 5px;
    align-self: center;
}


/* ========= SELECT CONFIRMATION METHOD ============== */
.select_confirm_user_method {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00008033;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease-in-out;
    z-index: 11111;
}
.select_confirm_user_method.show_verification_modal_auth {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
}
.select_confirm_user_method .confirm_method_content {
    width: 85%;
    height: 18rem;
    border-radius: 4px;
    background-color: #fff;

    max-width: 500px;
    padding: 1rem;
    font-size: 0.8rem;
    position: relative;
}
.select_confirm_user_method .close_confirm_method_modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.select_confirm_user_method .close_confirm_method_modal:hover {
    background-color: #ddd;
}
.select_confirm_user_method .confimation_title {
    text-align: center;
    margin-block: 2rem;
    margin: 32px auto;
}
.confirmation_methods_flex {
    display: flex;
    justify-content: center;
    align-items: center;
}
.confirmation_methods_flex .account_confirm_method:nth-child(1){
    margin-right: 1rem;
}
.confirmation_methods_flex .account_confirm_method:nth-child(2){
    margin-left: 1rem;
}
.select_confirm_user_method .account_confirm_method {
    /* width: 80%;
    text-align: center; */
    cursor: pointer;
}

.select_confirm_user_method .method_icon {
    width: 80px;
    height: 50px;
    background-color: #fff;
    border: 1.4px solid var(--gray);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
}
.select_confirm_user_method .method_icon * {
    font-size: 1.6rem;
    transition: all .3s ease;
    color: var(--gray);
}
.select_confirm_user_method .account_confirm_method p {
    text-align: center;
    margin-block: 1rem;
    font-weight: bold;
}

.select_confirm_user_method .submitBtn {
    margin-block: 0.8rem;
}
.account_confirm_method.selected_verification_method .method_icon,
.select_confirm_user_method .method_icon:hover {
    border: 1.4px solid #1010d8c5;
}

.account_confirm_method.selected_verification_method .method_icon * {
    color: #0056d6;
}


/* ========= VERIFY NUMBER ============= */
.verify_number_container {
    width: 100%;
    max-width: 500px;
    min-height: 15rem;
    border-radius: 5px;
    border: 2px solid #ddd;
    background-color: #fff;
    margin: 3rem auto;
    font-size: 0.9rem;
    padding: 1rem;
    margin-top: 7rem;
}
.verify_number_container .verify_number_title {
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #999;
    margin-left: 50px;
    margin-bottom: 1rem;
}
.verify_number_container .verify_number_title span{
    margin-right: 0;
}
.verify_number_container .verification_number_fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
    flex-wrap: nowrap;
}
.verification_number_fields {
    width: 85%;
    margin: auto;
}
.verification_number_fields_mobile {
    width: 80%;
    margin: auto;
    height: 2.5rem;
    border-radius: 2px solid #ddd;
    overflow: hidden;
    margin-bottom: 1rem;
}
.verification_number_fields_mobile input {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 1rem;
}
.verify_number_container .verification_letter {
    width: 19%;
    height: 4rem;
    border-radius: 4px;
    border: 2px solid #e2e2e2;
    overflow: hidden;
    position: relative;
}
.verify_number_container .verification_letter .verification_input_number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0.85rem;
    text-align: center;
}
.verify_number_container .verify_number_btn {
    width: 80%;
    margin: 2rem auto 0rem;
    text-align: center;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    padding: 0.8rem;
    cursor: pointer;
}
.verify_number_container .verify_number_btn:hover{
    background-color: #011b33;
    transition: 0.2s ease;
}
.verification_number_description {
    width: 80%;
    margin: auto;
}
.verification_number_description .code_not_sent {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    font-weight: bolder;
    font-size: 0.85rem;
}
.verification_number_description .code_not_sent .link {
    color: blue;
    font-weight: 500;
    margin-left: 0.6rem;
    text-decoration: underline;
    cursor: pointer;
}
.ver_code_timer {
    margin-top: 1rem;
    font-size: 0.8rem;
    font-weight: bolder;
}

@media(min-width:50rem){

    .authenticate{
        display: flex;
        margin: auto;
        min-height: 80vh;
        /* overflow: hidden; */
    }
    .forgot_password_n, .forgot_password_n .authenticate{
        min-height: 60vh;
    }
    .forgot_password_n .authenticate__content{
        box-shadow: 0px 0px 20px 0px #dbe0e05d;
        
    }
    .authenticate .mobile-image{
        display: none;
    }
    .authenticate__image_wrapper{
        width: 30%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: relative;
    }
    .authenticate__image_wrapper img{
        position: absolute;
        top: 50%;
        left: 0%;
        width: 100%;
        transform: translate(0%, -50%);
    }
    .authenticate__content{
        padding: 1rem;
        display: flex;
        width: 95%;
        padding: 3rem 2rem;
        margin-bottom: 4rem;
        align-items: center;
        border: none;
        box-shadow: 0px 0px 20px 0px #dbe0e094;
        
    }

    .imageWrapper img{
        width: auto;
        height: 100%;
    }
    .inputField{
        margin: 1rem 0rem;
    }
    .authenticate__content h3{
        font-size: 1.75rem;
        text-align: center;
        margin: 1rem;
        color: #999;
    }
    .authenticate__content_container {
        width: 75%;
        padding-right: 2rem;
        margin-right: 1rem;
        border-right: 1px solid rgba(158, 158, 158, 0.438);
    }

    .iconRegister {
        color: #1a7cfd;
        margin-right: 1rem;
        padding-top: .5rem;
    }
    .convince_item {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }
    .convince_item p{
        margin-top: 1rem;
        text-align: left;
    }

    .verify_number_container .verification_letter .verification_input_number {
        font-size: 1.5rem;
    }
    .authenticate__content .buttonWrapper.signup {
        display: flex;
    }
    .submitBtn.register {
        padding-inline: 3rem ;
        margin-block: 3rem;
    }
    .submitBtn.changeMethod {
        margin-block: 3rem;
    }
    .verify_number_container .verification_number_fields {
        display: flex;
    }

    .verification_number_fields_mobile {
        display: none;
    }
}

@media(min-width: 75rem ){
    .authenticate__content{
        width: 75%;
        max-width: 75rem;
    }
}
@media(max-width: 50rem){
    .signup_convince {
        display: none;
    }
}