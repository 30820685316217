/* Conteneur centré */
.loader-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent; /* Page blanche */
  /* transform: translateY(-500px); */
}

/* Animation du spinner */
.spinner {
  border: 10px solid #f3f3f3; /* Couleur grise pour le fond */
  border-top: 10px solid #000080; /* Couleur bleue pour le dessus */
  border-radius: 50%;
  width: 200px;
  height: 200px;
  z-index: 1500;
  animation: spin 1.5s linear infinite; /* Animation de rotation */

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75%{
      transform: rotate(270deg)
  }
  100% {
    transform: rotate(360deg);
  }
}
  